import { useNavigate, useParams } from "react-router-dom";
import {Checkbox, useDropdown, useInput} from '../../../utils'
import DocumentType from '../../../models/dto/documentType';
import { useAppDispatch } from '../../../redux/hooks';
import { editDocumentType } from '../../../redux/reducers/documentTypesReducer';
import { updateDocumentType } from "../../../database/documentTypesQueries";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { EditButton } from "../../buttons";
import { pushNotificationMessage } from "../../notifcationMessage";
import {useEffect, useState} from "react";
import {fetchPlaybooks} from "../../../database/playbookQueries";
import Playbook from "../../../models/dto/playbook";
import DropdownItem from "../../../models/dto/dropdownItem";

export default function EditDocumentTypeForm({ setDocumentType, documentType }: {setDocumentType:any, documentType: DocumentType}) {

    const [inputDocumentTypeId, documentTypeId, setDocumentTypeId] = useInput(documentType.id, "Document Type Id", documentType.id, true)
    const [inputDocumentTypeName, documentTypeName, setDocumentTypeName] = useInput(documentType.name, "Document Type Name", documentType.name)
    const [inputDocumentTypeShortName, documentTypeShortName, setDocumentTypeShortName] = useInput(documentType.short_name, "Document Type (Short Name)", documentType.short_name)
    const [inputDescription, description, setDescription] = useInput(documentType.description, "Description", documentType.description)

    const [rawPlaybooks, setPlaybooks] = useState<Playbook[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    useEffect(() => {
        const fetch_playbooks = async () => {
            const pbs = await fetchPlaybooks(supabase);
            setPlaybooks(pbs.playbooks)
        };
        const _response = fetch_playbooks()
    },[])

    const dropdownPlaybooks = rawPlaybooks.map(
        (item: Playbook) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    let initialPlaybookId = documentType.playbook_id // General Document Playbook

    const [dropdownPlaybookId, newPlaybookId, playbookName, selectedPlaybook, setPlaybookId] = useDropdown(
        initialPlaybookId,
        "Choose Playbook",
        dropdownPlaybooks,
        initialPlaybookId
    )


    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const docType = new DocumentType(
            description,
            documentTypeName,
            documentTypeShortName,
            documentType.playbook_id,
        )
        docType.id = documentTypeId

        const newDocType: DocumentType = await updateDocumentType(supabase, docType)

        if (!newDocType) {
            pushNotificationMessage("There was a problem while editing this Document Type", "error")
            return 
        }

        setDocumentType(newDocType)
    
        /** Reset */
        setDocumentTypeName("")
        setDocumentTypeShortName("")
        setDescription("")
        console.log(newDocType)
        dispatch(editDocumentType(newDocType))
        pushNotificationMessage("Document Type was edited with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputDocumentTypeId}
        {inputDocumentTypeName}
        {inputDocumentTypeShortName}
        {inputDescription}
        {dropdownPlaybookId}
        <br></br>
        <EditButton text='Save'/>
    </form>

}
