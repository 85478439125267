import React, { EventHandler, useEffect, useState } from 'react'
import { Checkbox, useDropdown, useInput } from '../../../utils'
import FollowUp from '../../../models/dto/followUp';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectPrompts } from '../../../redux/reducers/promptsReducer';
import Prompt from '../../../models/dto/prompt';
import DropdownItem from '../../../models/dto/dropdownItem';
import { addFollowUp } from '../../../redux/reducers/followUpsReducer';
import { insertFollowUp } from '../../../database/followUpQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { toggleFollowUpActivationWidget } from './utils';
import { CreateButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';

export default function FollowUpForm(
    { setFollowUp, initialPromptOriginId }: { setFollowUp:any, initialPromptOriginId?:string}
    ) {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // Prompts
    const rawPrompts = useAppSelector(selectPrompts);
    const dropdownPrompts = rawPrompts.map(
        (item: Prompt) => new DropdownItem(item.prompt_id, item.prompt_text, item, item.prompt_id, item.prompt_status)
    )

    console.log("initialPromptOriginId:", initialPromptOriginId)
    let originPromptPlaceholder = "Choose Anchor Prompt"
    if (initialPromptOriginId !== undefined) {
        originPromptPlaceholder = rawPrompts.filter((p) => p.prompt_id === initialPromptOriginId)[0]?.prompt_text
    }
    
    const [dropdownOriginPromptId, originPromptId, originPromptText, selectedOriginPrompt, setOriginPromptId] = useDropdown(initialPromptOriginId ?? "", "Anchor Prompt", dropdownPrompts, originPromptPlaceholder) 
    const [inputTriggerAnswer, triggerAnswer, setTriggerAnswer] = useInput("", "Trigger Answer", "Type trigger answer...")

    const [inputRiskScore, riskScore, setRiskScore] = useInput("", "Risk Score", "Type risk score...")
    const [dropdownTargetPromptId, targetPromptId, targetPromptText, selectedTargetPrompt, setTargetPromptId] = useDropdown("", "Target Prompt", dropdownPrompts, "Choose Target Prompt") 

    const [inputInfoNote, infoNote, setInfoNote] = useInput("", "Information Note", "Type Information Note...")

    const [isActive, setIsActive] = useState<boolean>(false)

    const toogleFollowUpActivation = (e: any) => {
        setIsActive(!isActive)
    }

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const f = new FollowUp(
            originPromptId,
            triggerAnswer,
            parseInt(riskScore),
            targetPromptId,
            infoNote,
            isActive
        )

        const newFollowUp: FollowUp = await insertFollowUp(supabase, f)

        if (!newFollowUp) {
            pushNotificationMessage("There was a problem while creating this Follow Up", "error")
            return 
        }
    
        setFollowUp(newFollowUp)
    
        /** Reset */
        setOriginPromptId("")
        setTriggerAnswer("")
        setRiskScore("")
        setTargetPromptId("")
        console.log(newFollowUp)
        dispatch(addFollowUp(newFollowUp))
        pushNotificationMessage("Follow Up was created with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {dropdownOriginPromptId}
        {inputTriggerAnswer}
        {inputRiskScore}
        {dropdownTargetPromptId}
        {inputInfoNote}
        <Checkbox headerText="Activate/Deactivate Follow Up" descriptionText="" toggleAction={toogleFollowUpActivation} currentState={isActive}/>
        <br></br>
        <CreateButton text='Add Follow Up'/>
    </form>

}
