import { useState } from "react"
import { useNavigate} from "react-router-dom";
import DropdownItem from "./models/dto/dropdownItem";
import Downshift from "downshift";
import cx from 'classnames';
import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid'

function useInput(initialState: string, 
                 labelText: string,
                 placeholder: string,
                 isReadOnly: boolean=false):[JSX.Element, string, React.Dispatch<React.SetStateAction<string>>]{
    const [text, setText] = useState(initialState)

    const handleChange = (e: any) => { setText(e.target.value) }

    const input = <div className="mt-5 mb-5">
        <label className="block text-sm font-medium text-gray-700">
            {labelText}
        </label>
        <div className="mt-1">
            {initialState !== "" ?
                <input type="text" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
                       onChange={handleChange} value={text} readOnly={isReadOnly}/>
                :
                <input type="text" placeholder={placeholder} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-3"
                    onChange={handleChange} readOnly={isReadOnly}/>
            }
        </div>
    </div>

    return [input, text, setText]
  }

const useDropdown: (initialState: string, 
    labelText: string,
    options: Array<DropdownItem>,
    placeholder: string) => [JSX.Element, string, string, any, React.Dispatch<React.SetStateAction<string>>]
=
(initialState, labelText, options, placeholder) => {
const [text, setText] = useState("")
const [selectedId, setId] = useState(initialState)
const [selectedObjectItem, setItem] = useState()

const downshiftDropdown = <Downshift
  onChange={
    selection => {
      setText(selection.text); 
      setId(selection.id);
      setItem(selection.item)
    }
  }
  itemToString={item => (item ? item.text : '')}
  >
  {({
    getInputProps,
    getItemProps,
    getMenuProps,
    getLabelProps,
    getToggleButtonProps,
    inputValue,
    highlightedIndex,
    selectedItem,
    isOpen,
  }) => (
    <div className="mt-5 mb-5">
      <div className="w-100 flex flex-col gap-1">
        <label {...getLabelProps()} className="block text-sm font-medium text-gray-700">{labelText}</label>
        <div className="flex shadow-sm bg-white gap-0.5 border border-black-800">
          <input
            placeholder={placeholder}
            className="w-full p-3"
            {...getInputProps()}
          />
          <button
            aria-label={'toggle menu'}
            className="px-10"
            {...getToggleButtonProps()}
          >
            {isOpen ? <>&#8593;</> : <>&#8595;</>}
          </button>
        </div>
      </div>
      <ul
        className="absolute w-100 bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0"
        style={{zIndex:9999}}
        {...getMenuProps()}
      >
        {isOpen
          ? options
              .filter(
                item =>
                  !inputValue ||
                  item.id?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                  item.text?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                  item.text_optional_1?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                  item.text_optional_2?.toLowerCase().includes(inputValue?.toLowerCase()),
              )
              .map((item, index) => (
                <li
                  className={cx(
                    highlightedIndex === index && 'bg-blue-300',
                    selectedItem === item && 'font-bold',
                    'py-2 px-3 shadow-sm flex flex-col',
                  )}
                  key={`${item.id}${index}`}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <span>{item.text}</span>
                  {item.text_optional_1 ? <span className="text-sm text-gray-700">
                    {item.text_optional_2 ? `${item.text_optional_1} | ${item.text_optional_2}` : item.text_optional_1}
                  </span>: null}
                </li>
              ))
          : null}
      </ul>
    </div>
  )}
</Downshift>

return [downshiftDropdown, selectedId, text, selectedObjectItem, setText]
}

const GoBackButton = ({text}:{text:string}) => {
    let navigate = useNavigate()
    return (
      <button
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-5"
        onClick={() => navigate(-1)}
      >
      <ArrowLeftCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      {text}
      </button>
    )
}

function Checkbox({headerText, descriptionText, toggleAction, currentState}: 
  {headerText: string, descriptionText: string, toggleAction: any, currentState:boolean}) {
  return (
    <fieldset className="border-t border-b border-gray-200">
      <div className="divide-y divide-gray-200">
        <div className="relative flex items-start py-4">
          <div className="min-w-0 flex-1 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              {headerText}
            </label>
            <p id="comments-description" className="text-gray-500">
              {descriptionText}
            </p>
          </div>
          <div className="ml-3 flex h-5 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onClick={toggleAction} 
              checked={currentState}
            />
          </div>
        </div>
      </div>
    </fieldset>
  )
}

export {
    useInput,
    GoBackButton,
    useDropdown,
    Checkbox
}