import { Session, SupabaseClient } from "@supabase/supabase-js";
import { isUserInWhitelist } from "../database/security";

function isValidInternalUser(session: Session|null, supabase: SupabaseClient) {
    if (!session){
        return false
    }

    const hasUserAccessToInternalApp = isUserInWhitelist(session, supabase)

    console.log("hasUserAccessToInternalApp:", hasUserAccessToInternalApp)

    return hasUserAccessToInternalApp
}

export { isValidInternalUser }