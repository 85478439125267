import React, { useEffect, useState } from 'react'
import useDrivePicker from 'react-google-drive-picker/dist';
import { PickerCallback, PickerConfiguration, authResult } from 'react-google-drive-picker/dist/typeDefs';
import axios from 'axios';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import JobsList from '../../../components/jobs/jobsList';


export default function TestDocumentTypesPage() {
  
  const [jobs, setJobs] = useState<any[]>([]);
  const [openPicker, authResponse] = useDrivePicker();  
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    let config: PickerConfiguration = {
      clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID ?? "",
      developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY ?? "",
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      customScopes: ['https://www.googleapis.com/auth/drive.readonly','https://www.googleapis.com/auth/drive.file'],
      callbackFunction: handleGoogleDrive,
    };
    return openPicker(config)
  }

  function handleGoogleDrive(data: PickerCallback) {
    if (data.action === 'cancel') {
      console.log('User clicked cancel/close button')
      return 
    }
    console.log("data:", data)
    for (let file of data.docs){
      let xhr = new XMLHttpRequest();
      xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${file['id']}?alt=media`)
      const token = authResponse?.access_token

      if (token === ""){
        console.log("[Problem]: token is empty")
        return
      }
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.setRequestHeader('Content-Type', 'application/vnd.google-apps.document');
      xhr.responseType = 'blob'
      xhr.onload = function () {
          let content = xhr.response
          console.log("content:", content)
          runJob(file['name'], content)
      };
      xhr.onerror = function () {
          console.log("ERROR LOADING THE FILE")
      };
      xhr.send();
    }
  }

  function runJob(filename: string, file_binary: any) {

    const formData = new FormData()
    formData.append('email_text', "")
    formData.append('source', "frederico@dfpas.com")
    formData.append('job_type', "review")
    formData.append('original_file', file_binary, filename)

    const requestOptions = {
      headers: { 'Content-Type': 'multipart/form-data'},
      // mode: "no-cors"
    } ;
    // Perform the upload using axios or fetch
    const res = axios.post(process.env.REACT_APP_BACKEND_DOMAIN + "/api/v1/jobs/" + `?token=${process.env.REACT_APP_BACKEND_TOKEN}`, formData, requestOptions)
              .then(result => {
                console.log(result)
              })
  }

  useEffect(() => {
    fetchAllDocumentsJobs();
  }, [])

  const fetchAllDocumentsJobs = async () => {
    console.log("Fetching all documents!!")
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    const response = await fetch(process.env.REACT_APP_BACKEND_DOMAIN+"/api/v1/jobs/?token="+process.env.REACT_APP_BACKEND_TOKEN, requestOptions)
    const result = await response.json();
    result.sort((a:any,b:any) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())).reverse()
    console.log("Documents Fetched:", result)
    setJobs(result.slice(0, 30))
  };

  return (
        <> <label className="block mb-2 text-md text-lg font-[500] Inter">Upload new documents</label>
      {/* <label htmlFor="file-upload" className="absolute max-w-[500px] font-bold"> */}
        <div className='max-w-[500px] border-2 border-dashed border-blue mt-1 mb-6 cursor-pointer rounded-2xl bg-[#3056D3]/[.1] shadow-xl'> 
        <label htmlFor="file-upload" className="block cursor-pointer font-bold py-20 pt-12 pb-12">
            <div className="flex text-sm text-gray-600 justify-center">     
                <ArrowDownOnSquareStackIcon className='h-10'/>         
                <input id="file-upload" name="file-upload" type="file" className="sr-only" onClick={() => handleOpenPicker()} />
            </div>
        </label>

        </div>
        <JobsList jobs={jobs}/>
      </> 
  )

}