import React, { useState } from 'react'
import { GoBackButton } from '../../../utils'
import DocumentType from '../../../models/dto/documentType';
import DocumentTypeForm from '../../../components/forms/documentType/documentTypeForm';

export default function CreateDocumentType() {

    const [documentType, setDocumentType] = useState<DocumentType>();

    return <>
        <h1 className="text-3xl font-bold">Document Type Creation Form</h1>
        <DocumentTypeForm setDocumentType={setDocumentType}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
