import Tag from "../../models/dto/tag"

interface RdxTagsState {
    data: Tag[],
    filter: string,
    loading: boolean,
    error: unknown
}

const tagsInitialState: RdxTagsState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxTagsState
}
export {
    tagsInitialState
}
