import React, { EventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDropdown, useInput } from '../../../utils'
import Prompt from '../../../models/dto/prompt'
import DropdownItem from '../../../models/dto/dropdownItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectTags } from '../../../redux/reducers/tagsReducer';
import Tag from '../../../models/dto/tag';
import Category from '../../../models/dto/category';
import { selectCategories } from '../../../redux/reducers/categoriesReducer';
import { selectDocumentTypes } from '../../../redux/reducers/documentTypesReducer';
import DocumentType from '../../../models/dto/documentType';
import { addPrompt } from '../../../redux/reducers/promptsReducer';
import { insertPrompt } from '../../../database/promptsQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { selectPromptTypes } from '../../../redux/reducers/promptsTypesReducer';
import { selectPromptAnswerTypes } from '../../../redux/reducers/promptsAnswerTypesReducer';
import PromptAnswerType from '../../../models/dto/promptAnswerType';
import PromptType from '../../../models/dto/promptType';
import { selectPromptsTemplates } from '../../../redux/reducers/promptsTemplatesReducer';
import PromptTemplate from '../../../models/dto/promptTemplate';
import { CreateButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';
import {fetchPlaybooks} from "../../../database/playbookQueries";
import Playbook from "../../../models/dto/playbook";

export default function PromptForm({ setPrompt}: any) {

    let {playbookId} = useParams<{playbookId: string}>()

    const [rawPlaybooks, setPlaybooks] = useState<Playbook[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // Playbooks
    useEffect(() => {
        const fetch_playbooks = async () => {
            const pbs = await fetchPlaybooks(supabase);
            setPlaybooks(pbs.playbooks)
        };
        const _response = fetch_playbooks()
    },[])

    const dropdownPlaybooks = rawPlaybooks.map(
        (item: Playbook) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Tags
    const rawTags = useAppSelector(selectTags);
    const dropdownTags = rawTags.map(
        (item: Tag) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Categories
    const rawCategories = useAppSelector(selectCategories);
    const dropdownCategories = rawCategories.map(
        (item: Category) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Prompt Types
    const rawPromptTypes = useAppSelector(selectPromptTypes);
    const dropdownPromptTypes = rawPromptTypes.map(
        (item: PromptType) => new DropdownItem(item.id, item.promptType, item, item.id)
    )

    // Prompt Answer Types
    const rawPromptAnswerTypes = useAppSelector(selectPromptAnswerTypes);
    const dropdownPromptAnswerTypes = rawPromptAnswerTypes.map(
        (item: PromptAnswerType) => new DropdownItem(item.id, item.answerType, item, item.id)
    )

    // Prompt Templates
    const rawPromptTemplates = useAppSelector(selectPromptsTemplates);
    const dropdownPromptTemplates = rawPromptTemplates.map(
        (item: PromptTemplate) => new DropdownItem(item.id, item.name, item, item.id, item.meta_template)
    )

    // Status
    const dropdownStatus = [
        new DropdownItem("PROD", "PROD", "PROD"),
        new DropdownItem("TEST", "TEST", "TEST")
    ]

    const initialPlaybookId = playbookId ? playbookId : ""

    const [inputPromptText, promptText, setPromptText] = useInput("", "Prompt Text", "Type prompt text...")
    const [dropdownTagId, tagId, tagName, selectedTag, setTagId] = useDropdown("", "Choose Tag", dropdownTags, "...")

    const [dropdownCategoryId, categoryId, categoryName, selectedCategory, setCategoryId] = useDropdown("", "Choose Category", dropdownCategories, "...")

    const [inputPromptType, promptTypeId, promptType, selectedpromptType, setPromptTypeId] = useDropdown("", "Choose Prompt Type", dropdownPromptTypes, "...")
    const [inputPromptAnswerType, promptAnswerTypeId, promptAnswerType, selectedPromptAnswerType, setPromptAnswerTypeId] = useDropdown("", "Choose Prompt Answer Type", dropdownPromptAnswerTypes, "...")

    const [dropdownPlaybookId, newPlaybookId, playbookName, selectedPlaybookType, setPlaybookId] = useDropdown(
        initialPlaybookId,
        "Choose Playbook",
        dropdownPlaybooks,
        initialPlaybookId
    )

    const [dropdownPromptTemplate, promptTemplateId, promptTemplateText, selectedPromptTemplate, setPromptTemplateId] = useDropdown("", "Choose Prompt Template", dropdownPromptTemplates, "...")
    const [dropdownStatusComponent, statusId, statusName, selectedStatus, setStausId] = useDropdown("", "Choose Status", dropdownStatus, "...")

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const p = new Prompt(
            promptText,
            tagId,
            categoryId,
            promptTypeId,
            promptAnswerTypeId,
            newPlaybookId,
            promptTemplateId,
            selectedStatus
        )

        const new_prompt: Prompt = await insertPrompt(supabase, p)

        if (!new_prompt) {
            pushNotificationMessage("There was a problem while creating this Prompt", "error")
            return 
        }

        setPrompt(p)
    
        /** Reset */
        setPromptText("")
        setCategoryId("")
        setPromptTypeId("")
        setPromptAnswerTypeId("")
        setPromptTemplateId("")
        setTagId("")
        setPlaybookId(new_prompt.playbook_id)
        console.log(new_prompt)
        dispatch(addPrompt(new_prompt))
        pushNotificationMessage("Prompt was created with Success", "success")
        //navigate(`/prompts/${new_prompt.prompt_id}/followUps`)
        navigate(-1)
    }

    const getCurrentPromptLook = (prompt: string, promptTemplate: PromptTemplate) => {
        let promptTemplateText = ""
        if (!promptTemplate) {promptTemplateText = prompt}
        else{
            promptTemplateText = promptTemplate.getTemplateTextSimplified()
            promptTemplateText = promptTemplateText ? promptTemplateText.replace("{{prompt_text}}", prompt ? prompt : "{{prompt_text}}") : prompt
        }
        return <>
            <div className="form-control w-full mt-5">
                <div className='w-full w-1/2'>
                    <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Full Prompt Look</label>
                    <textarea readOnly className="textarea textarea-secondary w-full text-lg min-h-[10rem] max-h-[20rem]" 
                    placeholder="This is a readonly text area, to display the full prompt look and feel."
                    value={promptTemplateText}></textarea>
                </div>
            </div>
        </>
    }

    return <>
        {inputPromptText}
        {inputPromptType}
        {inputPromptAnswerType}
        {dropdownTagId}
        {dropdownCategoryId}
        {dropdownPlaybookId}
        {dropdownPromptTemplate}
        {dropdownStatusComponent}
        {getCurrentPromptLook(promptText, selectedPromptTemplate)}
        <br></br>
        <form onSubmit={handleSubmit} className="mb-8 mt-8">
            <CreateButton text='Add Prompt'/>
        </form>
    </>

}
