
export const feedbackToScore = (feedback: any) => {
    return feedback ? feedback.feedback_score : 0
}
export const parseCommentsFromDocument = (document: any) => {
    let document_elements = document?.document_elements
    let comments = document_elements.map(
        (doc_element: any) => {
            for (let comment of doc_element?.comments) {
                comment["job_id"] = document?.job_id
            }
            return doc_element?.comments
        }
    )
    let flatten_comments = comments.flat()
    let sorted_comments_feedback = flatten_comments.sort((a: any, b: any) => feedbackToScore(a?.feedback)-feedbackToScore(b?.feedback))
    // sorted_comments (sorting logic): lowest score -> biggest score, since we are more concerned with low scores.
    let sorted_comments_date = sorted_comments_feedback.sort((a: any, b: any) => (a?.feedback?.created_at).localeCompare(b?.feedback?.created_at))
    return sorted_comments_date
}

export const parseCommentsFromAllDocuments = (documents: Array<any>) => {
    let documentComments = documents.map(
        (document: any) => parseCommentsFromDocument(document)
    )
    return documentComments.flat()
}
