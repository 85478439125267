import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import Prompt from '../../../models/dto/prompt';
import { redirect, Form, useParams} from "react-router-dom";
import EditPromptForm from '../../../components/forms/prompt/editPromptForm';
import { useAppSelector } from '../../../redux/hooks';
import { selectPrompts } from '../../../redux/reducers/promptsReducer';

export default function EditPrompt() {

  let {promptId} = useParams<{promptId: string}>()

  const [prompt, setPrompt] = useState<Prompt>()

  const prompts = useAppSelector(selectPrompts)
  const oldPrompt = prompts.filter((p) => p.prompt_id === promptId)[0]  

  return <>
      <h1 className="text-3xl font-bold">Prompt Edition Form</h1>
      <EditPromptForm setPrompt={setPrompt} prompt={oldPrompt}/>
      <GoBackButton text={"Cancel"}/>
  </>

}
