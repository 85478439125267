import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, useInput } from '../../../utils'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { EditButton } from "../../buttons";
import { pushNotificationMessage } from "../../notifcationMessage";
import { useState } from "react";
import Playbook from "../../../models/dto/playbook";
import {updatePlaybook} from "../../../database/playbookQueries";

export default function EditPlaybookForm({ setPlaybook, playbook }: {setPlaybook: any, playbook: Playbook}) {

    const [inputPlaybookId, playbookId, setPlaybookId] = useInput(playbook.id, "Playbook Id", playbook.id, true)
    const [inputPlaybookName, playbookName, setPlaybookName] = useInput(playbook.name, "Playbook Name", playbook.name)
    const [inputPlaybookShortName, playbookShortName, setPlaybookShortName] = useInput(playbook.short_name, "Playbook (Short Name)", playbook.short_name)
    const [inputDescription, description, setDescription] = useInput(playbook.description, "Description", playbook.description)

    const [isActive, setIsActive] = useState<boolean>(playbook.active)

    const navigate = useNavigate()
    const supabase = useSupabaseClient()

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const playbook = new Playbook(
            description,
            playbookName,
            playbookShortName,
            isActive
        )
        playbook.id = playbookId

        const newPlaybook: Playbook = await updatePlaybook(supabase, playbook)

        if (!newPlaybook) {
            pushNotificationMessage("There was a problem while editing this Playbook", "error")
            return 
        }

        setPlaybook(newPlaybook)
    
        /** Reset */
        setPlaybookName("")
        setPlaybookShortName("")
        setDescription("")
        console.log(newPlaybook)
        pushNotificationMessage("Playbook was edited with Success", "success")
        navigate(-1)
    }

    const toogleIsActive = (e: any) => {
        setIsActive(!isActive)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputPlaybookId}
        {inputPlaybookName}
        {inputPlaybookShortName}
        {inputDescription}
        <Checkbox headerText="Activate/Deactivate Playbook" descriptionText="" toggleAction={toogleIsActive} currentState={isActive}/>
        <br></br>
        <EditButton text='Save Edit'/>
    </form>

}
