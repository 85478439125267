import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Prompt from "../../../models/dto/prompt";
import PromptsListItem from "./promptsListItem";
import { useEffect, useState } from "react";

export default function PromptsList( {prompts}: {prompts: Array<Prompt>}) {

  const [sorting, setSorting] = useState({ field: 'id', ascending: false })
  const [currentPrompts, setCurrentPrompts] = useState<Prompt[]>(prompts)

  function applySorting(field: string, ascending: boolean) {
    setSorting({ field: field, ascending: ascending });
  }

  useEffect(() => {
    setCurrentPrompts(
      prompts
    );
  }, [prompts]);

  useEffect(() => {
    // Copy array to prevent data mutation
    const currentPromptsCopy = [...prompts];

    // Apply sorting
    const sortedPrompts = currentPromptsCopy.sort((p1: Prompt, p2: Prompt) => {
      const field: string = sorting.field
      const p1_m = p1.toDict()
      const p2_m = p2.toDict()
      const p1_m_field = `${p1_m.get(field)}`
      const p2_m_field = `${p2_m.get(field)}`
      return p1_m_field.localeCompare(p2_m_field, undefined, { numeric: true, sensitivity: 'base'});
    });

    // Replace currentUsers with sorted currentUsers
    setCurrentPrompts(
      // Decide either currentUsers sorted by ascending or descending order
      sorting.ascending ? sortedPrompts : sortedPrompts.reverse()
    );
  }, [sorting]);

    return (<div className="mt-8 mb-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">View</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_id", !sorting.ascending)}>
                    Prompt Id
                    <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_id", !sorting.ascending)}>
                    Follow Ups Number
                    <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("contract_id", !sorting.ascending)}>
                    Playbook Id
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_text", !sorting.ascending)}>
                    Prompt Text
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_status", !sorting.ascending)}>
                    Prompt Status
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_type_id", !sorting.ascending)}>
                    Prompt Type
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("prompt_answer_type_id", !sorting.ascending)}>
                    Prompt Answer Type
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("tag_id", !sorting.ascending)}>
                    Tag Id
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("category_id", !sorting.ascending)}>
                    Category Id
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {
                currentPrompts.map(
                    (prompt: Prompt) => (
                        <PromptsListItem key={prompt.prompt_id} prompt={prompt}/>
                    )
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>)
}