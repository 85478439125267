import Category from "../../models/dto/category"

interface RdxCategoriesState {
    data: Category[],
    filter: string,
    loading: boolean,
    error: unknown
}

const categoriesInitialState: RdxCategoriesState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxCategoriesState
}
export {
    categoriesInitialState
}
