import { useNavigate, useParams } from "react-router-dom";
import { useInput } from '../../../utils'
import DocumentType from '../../../models/dto/documentType';
import { useAppDispatch } from '../../../redux/hooks';
import { editDocumentType } from '../../../redux/reducers/documentTypesReducer';
import { updateDocumentType } from "../../../database/documentTypesQueries";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import PromptTemplate from "../../../models/dto/promptTemplate";
import { EditButton } from "../../buttons";
import { useState } from "react";
import { MultipleSelect } from "../../multipleSelectItems";
import PromptTemplateModalForm from "./promptTemplateModalForm";
import { updatePromptTemplate } from "../../../database/promptsTemplatesQueries";
import { pushNotificationMessage } from "../../notifcationMessage";
import { editPromptTemplate } from "../../../redux/reducers/promptsTemplatesReducer";

export default function EditPromptTemplateForm(
    { setPromptTemplate, promptTemplate }: {
        setPromptTemplate:any, 
        promptTemplate: PromptTemplate 
    }) {

    const [openModel, setOpenModal] = useState(false)

    const [metaTemplateItems, setMetaTemplateItems] = useState<string[]>([])
    const [metaComponents, setMetaComponent] = useState<React.ReactElement<any>[]>([])
    const [isCustomTemplate, setIsCustomTemplate] = useState<boolean>(promptTemplate.custom_template !== "")
    const [usedMetaElements, setUsedElements] = useState<string[]>([])

    const [inputTemplateName, templateName, setTemplateName] = useInput(promptTemplate.name, "Template Name", promptTemplate.name)

    const [introduction, setIntroduction] = useState<string>(promptTemplate.metaItems["introduction"])
    const [warning, setWarning] = useState<string>(promptTemplate.metaItems["warning"])
    const [instructions, setInstructions] = useState<string>(promptTemplate.metaItems["instructions"])
    const [conditional_information, setConditionalInformation] = useState<string>(promptTemplate.metaItems["conditional_information"])
    const [dynamic_conditional_information, setDynamicConditionalInformation] = useState<string>(promptTemplate.metaItems["dynamic_conditional_information"])

    const [few_shots, setFewShots] = useState<string[]>(promptTemplate.metaItems["few_shots"])
    const [space, setSpace] = useState<string>(promptTemplate.metaItems["space"])
    const [final_space, setFinalSpace] = useState<string>(promptTemplate.metaItems["final_space"])
    const [customTemplate, setCustomTemplate] = useState<string>(promptTemplate.custom_template)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    const setMetaElement = (metaElement: string) => {
        switch (metaElement) {
            case "instruction":
                setIntroduction(metaElement)
                break
            case "warning":
                setWarning(metaElement)
                break
            case "instructions":
                setInstructions(metaElement)
                break
            case "conditional_information":
                setConditionalInformation(metaElement)
                break
            case "dynamic_conditional_information":
                setDynamicConditionalInformation(metaElement)
                break
            
        }
    }

    const addMetaComponent = (component: React.ReactElement<any>, metaElementUsed: string) => {
        const localMetaComponents = [...metaComponents, component]
        const localUsedMetaElements = [...usedMetaElements, metaElementUsed]
        setMetaElement(metaElementUsed)
        setUsedElements(localUsedMetaElements)
        setMetaComponent(localMetaComponents)
    }

    const handleSubmit = async (e: any) => { 
        e.preventDefault()
        //user input
        const metaTemplate = metaTemplateItems.join("|")
        const updatedPromptTemplate = new PromptTemplate(
            promptTemplate.id,
            templateName,
            metaTemplate,
            introduction,
            warning,
            instructions,
            conditional_information,
            dynamic_conditional_information,
            few_shots,
            space,
            final_space,
            customTemplate
        )

        console.log("promptTemplate:", updatedPromptTemplate)
        const newPromptTemplate: PromptTemplate = await updatePromptTemplate(supabase, updatedPromptTemplate)

        if (!newPromptTemplate) {
            pushNotificationMessage("There was an issue updating Prompt Template! ", "error")
            return 
        }
        
        dispatch(editPromptTemplate(updatedPromptTemplate))
        pushNotificationMessage("Prompt Template Updated with Success", "success")
        navigate(-1)
    }

    const handleCustomTemplateChange = (e:any) => {
        setCustomTemplate(e.target.value)
    }

    const customTemplateWidget = (isActive: boolean) => <>
        {isActive ? <>
            <div className="form-control w-full">
                <div className='w-full w-1/2'>
                    <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Custom Prompt Template</label>
                    <textarea className="textarea textarea-secondary w-full text-lg min-h-[10rem] max-h-[20rem]" placeholder="Enter template: use {{variable_name}} for dynamic variables" onChange={handleCustomTemplateChange} value={customTemplate}></textarea>
                </div>
            </div>
        </> : ""}
    </>

const normalTemplateCreationFlow = (isActive: boolean) => <>
{!isActive ? <>
    <MultipleSelect 
        placeholderHeaderText={"Prompt Template Items"}
        placeholderText={"Choose Items"}
        items={PromptTemplate.getFields().map((pt, index) => {return {id: index, text: pt}})} 
        setSelectedItems={setMetaTemplateItems}
    />
    <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    onClick={() => setOpenModal(true)}>
        Add Meta Template Item
    </button>
    <PromptTemplateModalForm addMetaComponent={addMetaComponent} usedMetaElements={usedMetaElements} open={openModel} setOpen={setOpenModal}/>
    {metaComponents}
</> : ""}
</>

    return <>
        {inputTemplateName}
        {customTemplateWidget(isCustomTemplate)}
        {normalTemplateCreationFlow(isCustomTemplate)}
        <form onSubmit={handleSubmit} className="mb-8 mt-8">
            <EditButton text='Save'/>
        </form>
    </>

}
