import React, { EventHandler, useEffect, useState } from 'react'
import { useDropdown, useInput } from '../../../utils'
import DropdownItem from '../../../models/dto/dropdownItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Tag from '../../../models/dto/tag';
import Category from '../../../models/dto/category';
import { selectCategories } from '../../../redux/reducers/categoriesReducer';
import { useNavigate } from 'react-router-dom';
import { editTag } from '../../../redux/reducers/tagsReducer';
import { updateTag } from '../../../database/tagsQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { EditButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';

export default function EditTagForm(
    { setTag, tag}: {setTag: any, tag: Tag}
    ) {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // Categories
    const rawCategories = useAppSelector(selectCategories);
    const dropdownCategories = rawCategories.map(
        (item: Category) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    const [inputTagId, tagId, setTagId] = useInput(tag.id, "Tag Id", tag.id, true)
    const [inputTagName, tagName, setTagName] = useInput(tag.name, "Tag Name", tag.name)
    const [inputDescription, description, setDescription] = useInput(tag.description, "Tag Description", tag.description)

    const oldCategoryName = rawCategories.filter((c) => c.id === tag.categoryId)[0]?.name ?? "..."

    const [dropdownCategoryId, categoryId, categoryName, selectedCategory, setCategoryId] = useDropdown(tag.categoryId, "Choose Category", dropdownCategories, oldCategoryName)

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const t = new Tag(
            tagName,
            description,
            categoryId,
        )
        t.id = tagId

        const new_tag: Tag = await updateTag(supabase, t)

        if (!new_tag) {
            pushNotificationMessage("There was a problem while editing this Tag", "error")
            return 
        }

        setTag(new_tag)
    
        /** Reset */
        setTagId("")
        setTagName("")
        setDescription("")
        setCategoryId("")
        console.log(new_tag)
        dispatch(editTag(new_tag))
        pushNotificationMessage("Tag was edited with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8" >
        {inputTagId}
        {inputTagName}
        {inputDescription}
        {dropdownCategoryId}
        <br></br>
        <EditButton text='Save'/>
    </form>

}
