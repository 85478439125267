import { Session } from "@supabase/supabase-js"
import InternalUser from "../../models/dto/internalUser"

interface RdxUserState {
    session: Session|null,
    user: InternalUser|null,
    role: string,
    error: unknown
}

const userInitialState: RdxUserState = {
    session: null,
    user: null,
    role: '',
    error: null
}

export type {
    RdxUserState
}
export {
    userInitialState
}
