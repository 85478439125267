export default class Tag{
    id: string;
    name: string;
    description: string;
    categoryId: string;

    constructor(    
        name: string,
        description: string,
        categoryId: string
    ){
        this.id = "-1";
        this.name = name;
        this.description = description;
        this.categoryId = categoryId;
    }

    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["name", this.name],
            ["description", this.description],
            ["categoryId", this.categoryId]
        ])
    }
}