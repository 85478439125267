import React, { EventHandler, useEffect, useState } from 'react'
import { redirect, Form, useParams} from "react-router-dom";
import FollowUp from '../../../models/dto/followUp';
import FollowUpForm from '../../../components/forms/followUp/followUpForm';
import { GoBackButton } from '../../../utils';

export default function CreateFollowUp() {
    let {promptId} = useParams<{promptId: string}>();
    const [followUp, setFollowUp] = useState<FollowUp>();

    return <>
        <h1 className="text-3xl font-bold">Follow Up Creation Form</h1>
        <FollowUpForm setFollowUp={setFollowUp} initialPromptOriginId={promptId}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
