import FollowUp from "../../models/dto/followUp"

interface RdxFollowUpsState {
    data: FollowUp[],
    filter: string,
    loading: boolean,
    error: unknown
}

const followUpsInitialState: RdxFollowUpsState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxFollowUpsState
}
export {
    followUpsInitialState
}
