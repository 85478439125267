import { useState, useEffect } from "react";
import { NavLink} from 'react-router-dom'

import { CreateButton } from "../../../components/buttons";
import {fetchPlaybooks} from "../../../database/playbookQueries";
import PlaybooksList from "./playbooksList";
import Playbook from "../../../models/dto/playbook";
import {useSupabaseClient} from "@supabase/auth-helpers-react";

export default function PlaybooksPage() {

    const [playbooks, setPlaybooks] = useState<Playbook[]>([])
    const supabase = useSupabaseClient()

    useEffect(() => {
        const fetch_playbooks = async () => {
            const pbs = await fetchPlaybooks(supabase);
            console.log(pbs)
            setPlaybooks(pbs.playbooks)
        };
        const _response = fetch_playbooks()
    },[])

    return <> 
        <h1 className="text-3xl font-bold mb-5">Playbooks Page</h1>
        <NavLink to={`/playbooks/new`}>
            <CreateButton text="Add Playbook"/>
        </NavLink>
        <PlaybooksList playbooks={playbooks}/>
        </>
}