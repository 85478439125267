import { useState } from "react";
import AppSideHeader from "./header/appSideHeader";
import AppMain from "./main";
import { Bars3Icon } from "@heroicons/react/24/outline";
import ScrollToTop from "../components/scrolling";

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }

export default function AppLayout() {

    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div>
            <AppSideHeader />
            <div className="flex flex-1 flex-col md:pl-64">
                <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
                    <button
                    type="button"
                    className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <main className="flex-1">
                    <div className="py-6">
                        <div className="px-4 sm:px-6 md:px-8">
                            <ScrollToTop />
                            <AppMain />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}