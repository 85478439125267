import React, { EventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useInput } from '../../../utils'
import { useAppDispatch } from '../../../redux/hooks';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { CreateButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';
import Playbook from "../../../models/dto/playbook";
import {insertPlaybook} from "../../../database/playbookQueries";

export default function PlaybookCreationForm({ setPlaybook }: any) {

    const [inputPlaybookName, playbookName, setPlaybookName] = useInput("", "Playbook Name", "Type Playbook Name...")
    const [inputPlaybookShortName, playbookShortName, setPlaybookShortName] = useInput("", "Playbook (Short Name)", "Type Playbook Short Name...")
    const [inputDescription, description, setDescription] = useInput("", "Description", "Type Description...")

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    const handleSubmit = async (e: any) => { 
        e.preventDefault();
        //user input
        const playbook = new Playbook(
            description,
            playbookName,
            playbookShortName,
            false
        )

        const newPlaybook: Playbook = await insertPlaybook(supabase, playbook)

        if (!newPlaybook) {
            pushNotificationMessage("There was a problem while creating this Playbook", "error")
            return 
        }
        setPlaybook(newPlaybook)
        /** Reset */
        setPlaybookName("")
        setPlaybookShortName("")
        setDescription("")
        console.log(newPlaybook)
        pushNotificationMessage("Playbook was created with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputPlaybookName}
        {inputPlaybookShortName}
        {inputDescription}
        <br></br>
        <CreateButton text='Save Playbook'/>
    </form>

}
