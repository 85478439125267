import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { RdxUserState, userInitialState } from '../states/userState';
import InternalUser from '../../models/dto/internalUser';

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState as RdxUserState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    signInUser: (state: RdxUserState, action: PayloadAction<InternalUser>) => {
        const user: InternalUser = action.payload;
        state.user = user
    },
    signOutUser: (state: RdxUserState, action: PayloadAction<InternalUser>) => {
      const user: InternalUser = action.payload;
      state.user = null
    },
  },
});

export const { signInUser, signOutUser } = userSlice.actions;


export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;