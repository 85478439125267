import {useEffect, useState} from 'react';
import JobsList from "../../../components/jobs/jobsList";

export default function AnalysisPage() {

    const [jobs, setJobs] = useState<any[]>([]);

    useEffect(() => {
        fetchAllDocumentsJobs();
    },[])

    const fetchAllDocumentsJobs = async () => {
        console.log("Fetching all documents!!")
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };
        const response = await fetch(process.env.REACT_APP_BACKEND_DOMAIN+"/api/v1/jobs/?token="+process.env.REACT_APP_BACKEND_TOKEN, requestOptions)
        let result_jobs = await response.json();
        result_jobs = result_jobs.filter((j: any) => j?.job_type === "review")
        //result_jobs.sort((a:any,b:any) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())).reverse()
        result_jobs.sort((a:any,b:any) => parseInt(b.id) - parseInt(a.id)) // sorted by id (bigger->lower)
        console.log("Documents Fetched:", result_jobs)
        setJobs(result_jobs.slice(0, 10))
    };

    return (
        <JobsList jobs={jobs}/>
    );
}