
import PromptAnswerType from "../models/dto/promptAnswerType";

export async function fetchPromptAnswerTypes(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('prompt_answer_type')
      .select(`id, answer_type`)

    if (error && status !== 406) {
        throw error
    }

    let promptAnswerTypes: Array<PromptAnswerType> = []
    if (data) {
        promptAnswerTypes = data.map(
            function(t: any) {
                const prompt_answer_type = new PromptAnswerType(
                    String(t.id), 
                    t.answer_type, 
                )
                return prompt_answer_type
            }
        )
    }

    return { promptAnswerTypes, error, status }
  }