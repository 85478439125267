import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { removePrompt, selectPrompts } from "../../../redux/reducers/promptsReducer";
import PromptInfo from "./promptInfo";
import FollowUpsList from "../followUpsViews/followUpsList";
import { selectFollowUps } from "../../../redux/reducers/followUpsReducer";
import { deletePrompt } from "../../../database/promptsQueries";
import Prompt from "../../../models/dto/prompt";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { CreateButton, DeleteButton, EditButton } from "../../../components/buttons";
import Divider from "../../../components/dividers";
import { popUpConfirmAlert } from "../../../components/confirmPopUp";
import { pushNotificationMessage } from "../../../components/notifcationMessage";

export default function PromptDetail() {
    let {promptId} = useParams<{promptId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    console.log("promptId: ", promptId)
    
    const prompts = useAppSelector(selectPrompts);
    const prompt = prompts.filter((p) => p.prompt_id === promptId)[0]

    console.log("prompt:", prompt)

    const followUps = useAppSelector(selectFollowUps);
    const filteredFollowUps = followUps.filter((f) => f.originPromptId === prompt.prompt_id)

    console.log("filteredFollowUps:", filteredFollowUps)

    const delPrompt= async () => {
        const deletedPrompt: Prompt = await deletePrompt(supabase, prompt)

        if (!deletedPrompt) {
            pushNotificationMessage("There was an issue while deleting Prompt", "error")
            return 
        }
        dispatch(removePrompt(promptId ? promptId : ""))
        pushNotificationMessage("Prompt was deleted with Success", "success")
        navigate("/prompts")
    }

    return <>
        <PromptInfo prompt={prompt}/>
        <div className="inline-flex items-center">
            <NavLink to={`/prompts/${promptId}/edit`}>
                <EditButton text="Edit Prompt"/>
            </NavLink>
            <div className='ml-5'>
                <DeleteButton text="Delete Prompt" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Prompt.",
                    `Are you sure you want to delete Prompt: ${promptId}? This Action cannot be undone.`,
                    delPrompt,
                    () => {}
                )}/>
            </div>
        </div>

        <Divider text="Prompt Follow Ups"/>

        <NavLink to={`/prompts/${promptId}/followUps/new`}>
            <CreateButton text="Add Follow Up"/>
        </NavLink>
        <FollowUpsList followUps={filteredFollowUps}/>
        </>
}