import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function pushNotificationMessage(message: string, type: string) {

    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    } as ToastOptions

    const getNotificationMetaUI = () => {
        if (type === "success") {
            return toast.success(message, options)
        }
        else if (type === "warning") {
            return toast.warn(message, options)
        }
        else if (type === "error") {
            return toast.error(message, options)
        }
        else {
            return toast.info(message, options)
        }
    }
    getNotificationMetaUI()

}