
import { NavLink } from 'react-router-dom'
import PromptsList from "./promptsList";
import { useParams } from 'react-router-dom';
import { selectPrompts } from "../../../redux/reducers/promptsReducer";
import { useAppSelector } from "../../../redux/hooks";
import { CreateButton } from "../../../components/buttons";

export default function PromptsPage() {

    let {documentTypeId} = useParams<{documentTypeId: string}>();

    const prompts = useAppSelector(selectPrompts);

    return <>
        <h1 className="text-3xl font-bold mb-5">Prompts Page</h1>
        <NavLink to={`/prompts/new`}>
            <CreateButton text="Add New Prompt"/>
        </NavLink>
        <PromptsList prompts={prompts}/>
    </>
}