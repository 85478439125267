import {useEffect, useState} from 'react';
import Loading from "../../../components/loader";
import { fetchJobDocumentElementsCommentsWithAnyFeedback } from "../../../database/analsyisQueries";
import {parseCommentsFromAllDocuments, parseCommentsFromDocument} from "./feedback_utils";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import FeedbacksList from "./feedbacksList";

export default function FeedbacksPage() {

    const supabase = useSupabaseClient()

    const [jobs, setJobs] = useState<any[]>([]);
    const [feedbacks, setFeedbacks] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        //fetchAllDocumentsJobs();
        fetchFeedbacksData();
    },[])

    const fetchAllDocumentsJobs = async () => {
        console.log("Fetching all documents!!")
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };
        const response = await fetch(process.env.REACT_APP_BACKEND_DOMAIN+"/api/v1/jobs/?token="+process.env.REACT_APP_BACKEND_TOKEN, requestOptions)
        let result_jobs = await response.json();
        result_jobs = result_jobs.filter((j: any) => j?.job_type === "review")
        //result_jobs.sort((a:any,b:any) => (new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())).reverse()
        result_jobs.sort((a:any,b:any) => parseInt(b.id) - parseInt(a.id)) // sorted by id (bigger->lower)
        console.log("Documents Fetched:", result_jobs)
        setJobs(result_jobs.slice(0, 10))
    };

    const fetchFeedbacksData = async () => {
        const badFeedbacks = await fetchJobDocumentElementsCommentsWithAnyFeedback(supabase);
        let badFeedbacksData = badFeedbacks.data
        console.log("badFeedbacksData:", badFeedbacksData)
        setFeedbacks(parseCommentsFromAllDocuments(badFeedbacksData))
        setIsLoading(false)
        return badFeedbacksData
    };

    return <>
        <h1 className="text-3xl font-bold mb-5">Feedback Page</h1>
        {
            isLoading ? <Loading style={{}}/> : <FeedbacksList feedbacks={feedbacks}/>
        }
    </>
}
