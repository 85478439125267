
export async function fetchDocumentElementsCommentsWithBadFeedback(supabase: any) {

    let { data, error, status } = await supabase
        .from('document_request')
        .select('id, job_id, user_email, document_elements:document_element(id, comments:document_element_comment(id,document_element_id,comment_text,prompt_id,prompt_text,created_by,filled_by,tag:tags(tag_name),feedback:document_comment_feedback(feedback_score,feedback_text)))')
        .eq('comments.feedback.feedback_score', -1)

    if (error && status !== 406) {
        throw error
    }

    return { data, error, status }
}

export async function fetchJobDocumentElementsCommentsWithAnyFeedback(supabase: any) {

    const offset = 1000 * 60 * 60 * 24 * 10; //10 days before
    const myDate = new Date();
    myDate.setTime(myDate.getTime() - offset);

    const time_restriction = myDate.toISOString();

    let { data, error, status } = await supabase
        .from('document_request')
        .select('id, created_at, job_id, user_email, document_elements:document_element(id, comments:document_element_comment(id,document_element_id,comment_text,prompt_id,prompt_text,created_by,filled_by,tag:tags(tag_name),feedback:document_comment_feedback(created_at, feedback_score, feedback_text, created_by)))')
        .neq('document_elements.comments.feedback.feedback_score', 0)
        .not('document_elements.comments.feedback_id', 'is', null)
        .gt('created_at', time_restriction)

    if (error && status !== 406) {
        throw error
    }

    return { data, error, status }

}

export async function fetchJobDocumentElementsCommentsWithFeedback(supabase: any, job_id: number) {

    let { data, error, status } = await supabase
        .from('document_request')
        .select('id, job_id, user_email, document_elements:document_element(id, comments:document_element_comment(id,document_element_id,comment_text,prompt_id,prompt_text,created_by,filled_by,tag:tags(tag_name),feedback:document_comment_feedback(created_at, feedback_score, feedback_text, created_by)))')
        .eq('job_id', job_id)

    if (error && status !== 406) {
        throw error
    }

    return { data, error, status }
}
