
export default class DropdownItem{
    id: string;
    text: string;
    text_optional_1: string;
    text_optional_2: string;
    item: any;

    constructor(    
        id: string,
        text: string,
        item: any,
        text_optional_1: string = "",
        text_optional_2: string = ""
    ){
        this.id = id
        this.text = text
        this.item = item
        this.text_optional_1 = text_optional_1
        this.text_optional_2 = text_optional_2
    }
}