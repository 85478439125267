import { NavLink, useNavigate } from "react-router-dom";
import Prompt from "../../../models/dto/prompt"
import { useAppSelector } from "../../../redux/hooks";
import { selectPromptTypes } from "../../../redux/reducers/promptsTypesReducer";
import { selectPromptAnswerTypes } from "../../../redux/reducers/promptsAnswerTypesReducer";
import { selectFollowUps } from "../../../redux/reducers/followUpsReducer";
import {useEffect, useState} from "react";
import Playbook from "../../../models/dto/playbook";
import {fetchPlaybook} from "../../../database/playbookQueries";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import Loading from "../../../components/loader";

export default function PromptsListItem({prompt}: {prompt: Prompt}) {

    const supabase = useSupabaseClient()
    const [playbook, setPlaybook] = useState<Playbook>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetch_playbook = async () => {
            const pb = await fetchPlaybook(supabase, prompt.playbook_id);
            setPlaybook(pb.playbook)
            setIsLoading(false)
        };
        const _response = fetch_playbook()
    },[])

    const promptTypes = useAppSelector(selectPromptTypes)
    const promptType = promptTypes.filter((p) => p.id === prompt.prompt_type_id)[0] ?? {}

    const promptAnswerTypes = useAppSelector(selectPromptAnswerTypes)
    const promptAnswerType = promptAnswerTypes.filter((pat) => pat.id === prompt.prompt_answer_type_id)[0] ?? {}

    const followUps = useAppSelector(selectFollowUps);
    const promptFollowUps = followUps.filter((f) => f.originPromptId === prompt.prompt_id)
    const promptFollowUpsNumber = promptFollowUps.length

    const navigationView = (id:string) => `/prompts/${id}`
    const navigationEdit = (id:string) => `/prompts/${id}/edit`

    return <>
        <tr key={prompt.prompt_id}>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationView(prompt.prompt_id)} key={navigationView(prompt.prompt_id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                <span className="sr-only">, {prompt.prompt_id}</span>
            </NavLink>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <NavLink to={navigationEdit(prompt.prompt_id)} key={navigationEdit(prompt.prompt_id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
                <span className="sr-only">, {prompt.prompt_id}</span>
        </NavLink>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{prompt.prompt_id}</td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{promptFollowUpsNumber}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {isLoading ? <Loading style={{}}/> :
            prompt.playbook_id  + ` (${playbook?.name})`}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prompt.prompt_text}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {prompt.prompt_status}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prompt.prompt_type_id + ` (${promptType.promptType})`}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prompt.prompt_answer_type_id  + ` (${promptAnswerType.answerType})`}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prompt.tag_id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prompt.category_id}</td>
    </tr></>
}