import {NavLink} from "react-router-dom";
import classNames from "classnames";

export default function FeedbacksListItem({comment}: {comment: any}) {

    let job_id = comment?.job_id
    const url_path = `https://app.legalreview.ai/contract/${job_id}/inline_review?tag=${comment.id}`

    const navigationView = () => url_path

    const navigationEdit = () => `/prompts/${comment?.prompt_id}/edit`

    let feedback = comment?.feedback ? comment?.feedback?.feedback_score : 0
    let feedback_user = comment?.feedback ? comment?.feedback?.created_by : ""
    let feedback_date = comment?.feedback ? comment?.feedback?.created_at.slice(0, 16) : ""

    const activateClassNames = classNames(
        "inline-flex",
        "rounded-full",
        {
            "bg-green-100": feedback > 0,
            "bg-orange-100": feedback === 0,
            "bg-red-100": feedback < 0
        },
        "px-2",
        "text-xs",
        "font-semibold",
        "leading-5",
        {
            "text-green-800":  feedback > 0,
            "text-orange-800":  feedback === 0,
            "text-red-800":  feedback < 0,
        }
    )

    return <><tr key={comment.id}>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <a href={url_path} key={navigationView()} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
            </a>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationEdit()} key={navigationEdit()} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
            </NavLink>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{job_id}</td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{feedback_date}</td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{feedback_user}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <span className={activateClassNames}>
                  {String(feedback)}
              </span>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{comment.prompt_text}</td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{comment.comment_text}</td>
 
    </tr></>
}