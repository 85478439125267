import logger from 'redux-logger'
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import documentTypesReducer from './reducers/documentTypesReducer';
import promptsReducer from './reducers/promptsReducer';
import userReducer from './reducers/userReducer';
import followUpsReducer from './reducers/followUpsReducer';
import tagsReducer from './reducers/tagsReducer';
import categoriesReducer from './reducers/categoriesReducer';
import promptsTemplatesReducer from './reducers/promptsTemplatesReducer';
import promptsTypesReducer from './reducers/promptsTypesReducer';
import promptsAnswerTypesReducer from './reducers/promptsAnswerTypesReducer';


export const store = configureStore({
    reducer: {
        documentTypes: documentTypesReducer,
        prompts: promptsReducer,
        user: userReducer,
        followUps: followUpsReducer,
        tags: tagsReducer,
        categories: categoriesReducer,
        promptsTemplates: promptsTemplatesReducer,
        promptTypes: promptsTypesReducer,
        promptAnswerTypes: promptsAnswerTypesReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  })

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>