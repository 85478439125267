import Prompt from "../../../models/dto/prompt";
import { useState, useEffect } from "react";
import { Session } from "@supabase/supabase-js";
/*import { supabase } from '../../../security/supabaseClient'*/
import { NavLink} from 'react-router-dom'
import FollowUpsList from "./followUpsList";
import { useParams } from 'react-router-dom';
import FollowUp from "../../../models/dto/followUp";
import { selectFollowUps } from "../../../redux/reducers/followUpsReducer";
import { useAppSelector } from "../../../redux/hooks";
import { CreateButton } from "../../../components/buttons";

export default function FollowUpsPage() {

  const followUps = useAppSelector(selectFollowUps);

  return <>
    <h1 className="text-3xl font-bold mb-5">Follow Ups Page</h1>
    <NavLink to={`/followUps/new`}>
        <CreateButton text="Add Follow Up"/>
    </NavLink>
    <FollowUpsList followUps={followUps}/>
  </>
}