import React, { useEffect, useState } from 'react'
import { NavLink} from 'react-router-dom'
import AuthHeaderComponent from './auth'
import { Disclosure } from '@headlessui/react'
import { BoltIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/24/outline'
//import classNames from 'classnames'

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { 
    name: 'Dashboard', icon: HomeIcon, current: true, href: 
    <NavLink to={'/'} key={'home'} className="w-full">Home</NavLink>
  },
  { 
    name: 'Document Types', icon: ChartBarIcon, current: false, href: 
    <NavLink to={'/documentTypes'} key={'document_types'} className="w-full">Document Types</NavLink>
  },
  {
    name: 'Playbooks', icon: ChartBarIcon, current: false, href:
        <NavLink to={'/playbooks'} key={'playbooks'} className="w-full">Playbooks</NavLink>
  },
  {
    name: 'Prompts',
    icon: FolderIcon,
    current: false,
    children: [
      { name: 'Prompts List', current: false, href: 
        <NavLink to={'/prompts'} key={'prompts_lists'} className="w-full">
          Prompts List
        </NavLink>
      },
      { name: 'Prompts Templates', current: false, href: 
        <NavLink to={'/promptsTemplates'} key={'prompts_templates'} className="w-full">
          Prompts Templates
        </NavLink>
      },
    ],
  },
  { 
    name: 'Follow Ups', icon: ChartBarIcon, current: false, href: 
    <NavLink to={'/followUps'} key={'follow_ups'} className="w-full">Follow Ups</NavLink>
  },
  { 
    name: 'Tags', icon: ChartBarIcon, current: false, href: 
    <NavLink to={'/tags'} key={'tags'} className="w-full">Tags</NavLink>
  },
  { 
    name: 'Test Prompts', icon: BoltIcon, current: false, href: 
    <NavLink to={'/evaluate'} key={'evaluate'} className="w-full">Test Prompts</NavLink>
  },
  { 
    name: 'Test Document Types', icon: BoltIcon, current: false, href: 
    <NavLink to={'/testDocumentTypes'} key={'testDocumentTypes'} className="w-full">Test Document Types</NavLink>
  },
  {
    name: 'Analysis',
    icon: FolderIcon,
    current: false,
    children: [
      { name: 'Analysis', current: false, href:
            <NavLink to={'/analysis'} key={'analysis'} className="w-full">
              Document Comments
            </NavLink>
      },
      { name: 'Feedback', current: false, href:
            <NavLink to={'/comments_feedback'} key={'comments_feedback'} className="w-full">
              Feedback
            </NavLink>
      },
    ],
  },
]

export default function AppSideHeader() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [prevItem, setPrevItem] = useState<any>(navigation[0])  

    return (
    <div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
      <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-4">
            <img src="/logo_extended_vp.png" alt="Legalreview.ai logo with text" className='h-9'/>
          </div>
          <div className="mt-5 flex flex-grow flex-col">
            <nav className="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
              {navigation.map((item) =>
                !item.children ? (
                  <div key={item.name} onClick={()=>{item.current=true; prevItem.current=false; setPrevItem(item)}}>
                    <a
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.href}
                    </a>
                  </div>
                ) : (
                  <Disclosure as="div" key={item.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                          )}
                        >
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.name}</span>
                          <svg
                            className={classNames(
                              open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                            )}
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                              onClick={()=>{subItem.current=true; prevItem.current=false; setPrevItem(subItem)}}
                            >
                              {subItem.href}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>  
        </div>
        <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
            <a className="group block w-full flex-shrink-0">
              <div className="flex items-center">
                <div>
                <UsersIcon
                        className={classNames(
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">User</p>
                  <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                </div>
                <div>
                  <AuthHeaderComponent/>
                </div>
              </div>
            </a>
            </div>
        </div>
  </div>
)

}