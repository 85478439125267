
import { useState, useEffect } from 'react';
import PromptsList from '../promptsViews/promptsList';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  removeDocumentType,
    selectDocumentTypes
  } from '../../../redux/reducers/documentTypesReducer';

  import {
    selectPrompts
  } from '../../../redux/reducers/promptsReducer';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { CreateButton, DeleteButton, EditButton } from '../../../components/buttons';
import Divider from '../../../components/dividers';
import { popUpConfirmAlert } from '../../../components/confirmPopUp';
import { pushNotificationMessage } from '../../../components/notifcationMessage';
import PlaybookInfo from "./playbookInfo";
import Playbook from "../../../models/dto/playbook";
import {deletePlaybook, fetchPlaybook, fetchPlaybooks} from "../../../database/playbookQueries";
import DocumentTypesList from "../documentTypeViews/documentTypesList";
import DocumentType from "../../../models/dto/documentType";

export default function PlaybookDetail() {
    let {playbookId} = useParams<{playbookId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    const [playbook, setPlaybook] = useState<Playbook>()
    //let documentTypes = useAppSelector(selectDocumentTypes);
    //console.log("documentTypes:", documentTypes)
    //const [filteredDocumentTypes, setFilteredDocumentTypes] = useState<DocumentType[]>(documentTypes)

    useEffect(() => {
        const fetch_playbook = async () => {
            const pb = await fetchPlaybook(supabase, playbookId ? playbookId : "");
            setPlaybook(pb.playbook)
        };
        const _response = fetch_playbook()
    },[playbookId])

    console.log("here: ", playbookId)

    const prompts = useAppSelector(selectPrompts);
    const documentTypesPrompts = prompts.filter((p) => p.playbook_id === playbookId)

    const delPlaybook = async () => {
        const deletedPlaybook: Playbook = await deletePlaybook(supabase, playbook!)

        if (!deletedPlaybook) {
            pushNotificationMessage("There was an issue while deleting Playbook", "error")
            return 
        }
        dispatch(removeDocumentType(playbookId ? playbookId : ""))
        pushNotificationMessage("Playbook was deleted with Success", "success")
        navigate("/playbooks")
  }

    return (
        <div>
            {playbook ? <PlaybookInfo playbook={playbook}/>: <></>}

            <div className="inline-flex items-center">
                <NavLink to={`/playbooks/${playbookId}/edit`}>
                    <EditButton text="Edit Playbook"/>
                </NavLink>
                <div className='ml-5'>
                    <DeleteButton text="Delete Playbook" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Playbook.",
                    `Are you sure you want to delete Playbook: ${playbookId}? This Action cannot be undone.`,
                        delPlaybook,
                    () => {}
                )}/>
                </div>
            </div>

            {/*<Divider text="Playbook Document Types"/>

            <DocumentTypesList documentTypes={filteredDocumentTypes}/>*/}

            <Divider text="Playbook Prompts"/>

            <NavLink to={`/prompts/new`}>
                <CreateButton text="Add New Prompt"/>
            </NavLink>

            <PromptsList prompts={documentTypesPrompts}/>
        </div>
    )
}