import { NavLink, useNavigate } from "react-router-dom";
import FollowUp from "../../../models/dto/followUp"
import classNames from "classnames";

export default function FollowUpsListItem({followUp}: {followUp: FollowUp}) {
    
    const navigationView = (id:string) => `/followUps/${id}`
    const navigationEdit = (id:string) => `/followUps/${id}/edit`

    const activateClassNames = classNames(
        "inline-flex",
        "rounded-full",
        {
            "bg-green-100": followUp.active,
            "bg-red-100": !followUp.active
        },
        "px-2",
        "text-xs",
        "font-semibold",
        "leading-5",
        {
            "text-green-800": followUp.active,
            "text-red-800": !followUp.active
        }
    )

    return <><tr key={followUp.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{followUp.id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <span className={activateClassNames}>
                {String(followUp.active)}
            </span>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{followUp.originPromptId}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{followUp.triggerAnswer}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{followUp.riskScore}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{followUp.targetPromptId}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{followUp.note}</td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationView(followUp.id)} key={navigationView(followUp.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                <span className="sr-only">, {followUp.id}</span>
            </NavLink>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <NavLink to={navigationEdit(followUp.id)} key={navigationEdit(followUp.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
                <span className="sr-only">, {followUp.id}</span>
        </NavLink>
        </td>
    </tr></>
}