import React, { EventHandler, useEffect, useState } from 'react'
import { redirect, Form, useParams} from "react-router-dom";
import FollowUp from '../../../models/dto/followUp';
import { GoBackButton } from '../../../utils';
import { selectFollowUps } from '../../../redux/reducers/followUpsReducer';
import { useAppSelector } from '../../../redux/hooks';
import EditFollowUpForm from '../../../components/forms/followUp/editFollowUpForm';

export default function EditFollowUp() {
    let {followUpId} = useParams<{followUpId: string}>();

    const [followUp, setFollowUp] = useState<FollowUp>();

    const followUps = useAppSelector(selectFollowUps)
    const oldFollowUp = followUps.filter((f) => f.id === followUpId)[0]  

    console.log("fetched followUps: ", followUps)
    console.log("followUp:", oldFollowUp)

    return <>
        <h1 className="text-3xl font-bold">Follow Up Edition Form</h1>
        <EditFollowUpForm setFollowUp={setFollowUp} followUp={oldFollowUp}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
