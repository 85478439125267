import { ChevronDownIcon } from "@heroicons/react/20/solid";
import PromptTemplate from "../../../models/dto/promptTemplate";
import PromptsTemplatesListItem from "./promptsTemplatesListItem";
import { useEffect, useState } from "react";

export default function PromptsTemplatesList( {promptsTemplates}: {promptsTemplates: Array<PromptTemplate>}) {

  const [sorting, setSorting] = useState({ field: 'id', ascending: false })
  const [currentPromptsTemplates, setCurrentPromptsTemplates] = useState<PromptTemplate[]>(promptsTemplates)

  function applySorting(field: string, ascending: boolean) {
    setSorting({ field: field, ascending: ascending });
  }

  useEffect(() => {
    setCurrentPromptsTemplates(
      promptsTemplates
    );
  }, [promptsTemplates]);

  useEffect(() => {
    // Copy array to prevent data mutation
    const currentPromptsTemplatesCopy = [...promptsTemplates];

    // Apply sorting
    const sortedPromptsTemplates = currentPromptsTemplatesCopy.sort((p1: PromptTemplate, p2: PromptTemplate) => {
      const field: string = sorting.field
      const p1_m = p1.toDict()
      const p2_m = p2.toDict()
      const p1_m_field = `${p1_m.get(field)}`
      const p2_m_field = `${p2_m.get(field)}`
      return p1_m_field.localeCompare(p2_m_field, undefined, { numeric: true, sensitivity: 'base'});
    });

    // Replace currentUsers with sorted currentUsers
    setCurrentPromptsTemplates(
      // Decide either currentUsers sorted by ascending or descending order
      sorting.ascending ? sortedPromptsTemplates : sortedPromptsTemplates.reverse()
    );
  }, [sorting]);

    return (<div className="mt-8 mb-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-14">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">View</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("id", !sorting.ascending)}>
                    Id
                    <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("name", !sorting.ascending)}>
                    Name
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("meta_template", !sorting.ascending)}>
                    Meta Template
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("custom_template", !sorting.ascending)}>
                  Custom Template
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("introduction", !sorting.ascending)}>
                  Introduction
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("warning", !sorting.ascending)}>
                    Warning
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("instructions", !sorting.ascending)}>
                    Instructions
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("conditional_information", !sorting.ascending)}>
                    Conditional Information
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("dynamic_conditional_information", !sorting.ascending)}>
                    Dynamic Conditional Information
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("space", !sorting.ascending)}>
                    Space
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("final_space", !sorting.ascending)}>
                    Final Space
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {
                    currentPromptsTemplates.map(
                        (promptTemplate: PromptTemplate) => (
                            <PromptsTemplatesListItem key={promptTemplate.id} promptTemplate={promptTemplate}/>
                        )
                    )
                }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>)

}