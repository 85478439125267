import { useMultipleSelection, useSelect } from "downshift"
import cx from 'classnames';

export function MultipleSelect({placeholderHeaderText, placeholderText, items, setSelectedItems}: 
    {placeholderHeaderText: string, placeholderText: string, items: Array<any>, setSelectedItems: any}) {
    const {
      getSelectedItemProps,
      getDropdownProps,
      addSelectedItem,
      removeSelectedItem,
      selectedItems,
    } = useMultipleSelection<any>({initialSelectedItems: []})
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect({
      selectedItem: null,
      defaultHighlightedIndex: 0, // after selection, highlight the first item.
      items,
      stateReducer: (state, actionAndChanges) => {
        const {changes, type} = actionAndChanges
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
            }
        }
        return changes
      },
      onStateChange: ({type, selectedItem}) => {
        switch (type) {
          case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
          case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            if (selectedItem) {
              addSelectedItem(selectedItem)
              setSelectedItems([...selectedItems.map((i) => i.text), selectedItem.text])
            }
            break
          default:
            break
        }
      },
    })

    return (
      <div className="w-[592px] mt-5 mb-5">
        <div className="flex flex-col gap-1">
          <label className="w-fit" {...getLabelProps()}>
            {placeholderHeaderText}
          </label>
          <div className="shadow-sm bg-white inline-flex gap-2 items-center flex-wrap p-1.5 border-2 focus-within:border-gray-400">
            {selectedItems.map(function renderSelectedItem(
              selectedItemForRender,
              index,
            ) {
              return (
                <span
                  className="bg-gray-100 rounded-md px-1 focus:bg-red-400"
                  key={`selected-item-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                >
                  {selectedItemForRender.text}
                  <span
                    className="px-1 cursor-pointer"
                    onClick={e => {
                      e.stopPropagation()
                      removeSelectedItem(selectedItemForRender)
                    }}
                  >
                    &#10005;
                  </span>
                </span>
              )
            })}
            <div
              className="px-2 py-1 outline-2 outline-gray-400 cursor-pointer focus:bg-gray-200"
              {...getToggleButtonProps(
                getDropdownProps({preventKeyAction: isOpen}),
              )}
            >
              {placeholderText} &#8595;
            </div>
          </div>
        </div>
        <ul
          {...getMenuProps()}
          className="absolute p-0 bg-white shadow-md max-h-80 overflow-scroll w-inherit"
          style={{zIndex: 9999}}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                className={cx(
                  highlightedIndex === index && 'bg-blue-300',
                  selectedItem === item && 'font-bold',
                  'py-2 px-3 shadow-sm flex flex-col',
                )}
                key={`${item.id}${index}`}
                {...getItemProps({item, index})}
              >
                <span>{item.text}</span>
                <span className="text-sm text-gray-700">{item.id}</span>
              </li>
            ))}
        </ul>
      </div>
    )
}