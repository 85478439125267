import { Fragment, useState } from "react"
import DropdownItem from "../../../models/dto/dropdownItem"
import PromptTemplate from "../../../models/dto/promptTemplate"
import { useDropdown } from "../../../utils"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/20/solid"


export default function PromptTemplateModalForm(
    { addMetaComponent, usedMetaElements, open, setOpen }: {addMetaComponent: any, usedMetaElements: string[], open: boolean, setOpen:any},
    ) {

    let promptTemplateElementOptions = PromptTemplate.getFields()
    promptTemplateElementOptions = promptTemplateElementOptions.filter((o) => !usedMetaElements.includes(o))
    const dropdownTemplateElementItems = promptTemplateElementOptions.map((option, index) => new DropdownItem(String(index), option, option))
    const [dropdownTemplateElement, indexTemplateElement, nameTemplateElement, setIndexTemplateElement] = useDropdown("", "Template Element Type:", dropdownTemplateElementItems, "")

    const [text, setText] = useState<string>("")

    const buildMetaTemplateItem = (item: string, metaTemplateType: string) => <>
        <div className="overflow-hidden rounded-lg bg-white shadow mt-5">
            <div className="px-4 py-5 sm:p-6">
                <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-emerald">{metaTemplateType}</label>
                <label className="w-full text-lg">{item}</label>
            </div>
        </div>
    </>

    const addLocalComponent = () => {
        const htmlBuiltItem = buildMetaTemplateItem(text, nameTemplateElement)
        addMetaComponent(htmlBuiltItem, nameTemplateElement)
        
        setIndexTemplateElement("")
        setText("")
    }

    return (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
    
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Add Meta Template Item
                        </Dialog.Title>
                        <div className="mt-2">
                            {dropdownTemplateElement}
                            <div className="mt-5 mb-5">
                                <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Custom Prompt Template</label>
                                <textarea className="textarea textarea-secondary w-full text-lg min-h-[10rem] max-h-[20rem]" placeholder="Enter template: use {{variable_name}} for dynamic variables" 
                                onChange={(e) => setText(e.target.value)}></textarea>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                            setOpen(false)
                            addLocalComponent()
                        }}
                      >
                        Add Meta Template Item
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
    )

}
