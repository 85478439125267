import PromptType from "../../models/dto/promptType"


interface RdxPromptTypesState {
    data: PromptType[],
    filter: string,
    loading: boolean,
    error: unknown
}

const promptTypesInitialState: RdxPromptTypesState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxPromptTypesState
}
export {
    promptTypesInitialState
}
