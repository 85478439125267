import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { removePrompt, selectPrompts } from "../../../redux/reducers/promptsReducer";
import PromptTemplateInfo from "./promptTemplateInfo";
import FollowUpsList from "../followUpsViews/followUpsList";
import { selectFollowUps } from "../../../redux/reducers/followUpsReducer";
import { deletePrompt } from "../../../database/promptsQueries";
import Prompt from "../../../models/dto/prompt";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { removePromptTemplate, selectPromptsTemplates } from "../../../redux/reducers/promptsTemplatesReducer";
import { deletePromptTemplate } from "../../../database/promptsTemplatesQueries";
import PromptTemplate from "../../../models/dto/promptTemplate";
import { DeleteButton, EditButton } from "../../../components/buttons";
import Divider from "../../../components/dividers";
import {popUpConfirmAlert} from "../../../components/confirmPopUp"
import { pushNotificationMessage } from "../../../components/notifcationMessage";


export default function PromptTemplateDetail() {
    let {promptTemplateId} = useParams<{promptTemplateId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    console.log("promptTemplateId: ", promptTemplateId)
    
    const promptsTemplates = useAppSelector(selectPromptsTemplates);
    const promptTemplate = promptsTemplates.filter((pt) => pt.id === promptTemplateId)[0]

    console.log("prompt template:", promptTemplate)

    const delPromptTemplate= async () => {
        const deletedPromptTemplate: PromptTemplate = await deletePromptTemplate(supabase, promptTemplate)

        if (!deletedPromptTemplate) {
            pushNotificationMessage("There was an issue while deleting Prompt Template", "error")
            return 
        }
        dispatch(removePromptTemplate(promptTemplateId ? promptTemplateId : ""))
        pushNotificationMessage("Prompt Template was deleted with Success", "success")
        navigate("/promptsTemplates")
    }

    return <>
        <PromptTemplateInfo promptTemplate={promptTemplate}/>

        <div className="inline-flex items-center mt-2 mb-2">
            <NavLink to={`/promptsTemplates/${promptTemplateId}/edit`}>
                <EditButton text="Edit Prompt Template"/>
            </NavLink>
            <div className='ml-5'>
                <DeleteButton text="Delete Prompt Template" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Prompt Template.",
                    `Are you sure you want to delete Prompt Template: ${promptTemplateId}? This Action cannot be undone.`,
                    delPromptTemplate,
                    () => {}
                )}/>
            </div>
        </div>
        </>
}
