import DocumentType from "../../models/dto/documentType"

interface RdxDocumentTypesState {
    data: DocumentType[],
    filter: string,
    loading: boolean,
    error: unknown
}

const docTypesInitialState: RdxDocumentTypesState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxDocumentTypesState
}
export {
    docTypesInitialState
}
