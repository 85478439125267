import './App.css';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import AppFooter from './layout/footer/footer';
import { isValidInternalUser } from './security/utils';
import { GoBackButton } from './utils';
import { CustomAuth } from './components/security/customAuth';
import Main from './layout/main';
import AppSideHeader from './layout/header/appSideHeader';
import AppLayout from './layout/appLayout';
import { ToastContainer } from 'react-toastify';


export default function App() {

  /*useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
        setLoading(true)
        setSession(session)
    }).finally(() => {setLoading(false)})

    supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
    })
  }, [])*/

  const session = useSession()
  const supabase = useSupabaseClient()

  const [isValidUser, setIsValidUser] = useState<boolean>(false)

  useEffect(() => {
      supabase.auth.onAuthStateChange(async (event, session) => {
        if (event === 'SIGNED_IN') {
          const isValid = await isValidInternalUser(session, supabase)
          console.log("is Valid:", isValid)
          setIsValidUser(isValid)
          if (!isValid) supabase.auth.signOut()
        }
      })
  }, [])
  
  return (
    (!session || !isValidUser) ? <><CustomAuth/><AppFooter/></> : <div>
      <div className="flex flex-col h-screen">
        <Router>
          <ToastContainer />
          <AppLayout />
        </Router>
      </div>
    </div>
  )
}
