import { NavLink, useNavigate } from "react-router-dom";
import Tag from "../../../models/dto/tag"

export default function TagsListItem({tag}: {tag: Tag}) {
    
    const navigationView = (id:string) => `/tags/${id}`
    const navigationEdit = (id:string) => `/tags/${id}/edit`

    return <>
        <tr key={tag.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{tag.id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{tag.name}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{tag.description}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{tag.categoryId}</td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationView(tag.id)} key={navigationView(tag.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                <span className="sr-only">, {tag.id}</span>
            </NavLink>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <NavLink to={navigationEdit(tag.id)} key={navigationEdit(tag.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
                <span className="sr-only">, {tag.id}</span>
        </NavLink>
        </td>
    </tr></>
}