import PromptTemplate from "../../models/dto/promptTemplate"

interface RdxPromptsTemplatesState {
    data: PromptTemplate[],
    filter: string,
    loading: boolean,
    error: unknown
}

const promptsTemplatesInitialState: RdxPromptsTemplatesState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxPromptsTemplatesState
}
export {
    promptsTemplatesInitialState
}
