import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import Prompt from '../../../models/dto/prompt';
import { redirect, Form} from "react-router-dom";
import PromptForm from '../../../components/forms/prompt/promptForm';

export default function CreatePrompt() {
    const [prompt, setPrompt] = useState<Prompt>();

    return <>
        <h1 className="text-3xl font-bold">Prompt Creation Form</h1>
        <PromptForm setPrompt={setPrompt}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
