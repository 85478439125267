import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { RdxPromptTypesState, promptTypesInitialState } from '../states/promptTypesState';
import PromptType from '../../models/dto/promptType';
import { RdxPromptAnswerTypesState, promptAnswerTypesInitialState } from '../states/promptAnswerTypesState';
import PromptAnswerType from '../../models/dto/promptAnswerType';
import { fetchPromptAnswerTypes } from '../../database/promptAnswerTypesQueries';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPromptAnswerTypesAsync = createAsyncThunk(
  'promptAnswerTypes/fetchPromptAnswerTypes',
  async ({supabase}: {supabase:any}) => {
    const response = await fetchPromptAnswerTypes(supabase);
    // The value we return becomes the `fulfilled` action payload
    return response.promptAnswerTypes;
  }
);

export const promptAnswerTypesSlice = createSlice({
  name: 'promptAnswerTypes',
  initialState: promptAnswerTypesInitialState as RdxPromptAnswerTypesState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    receivePromptAnswerTypesList: (state: RdxPromptAnswerTypesState, action: PayloadAction<PromptAnswerType[]>) => {
        const promptAnswerTypes: PromptAnswerType[] = action.payload;
        state.data = [...state.data, ...promptAnswerTypes]
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromptAnswerTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromptAnswerTypesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPromptAnswerTypesAsync.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

export const { receivePromptAnswerTypesList } = promptAnswerTypesSlice.actions;

export const selectPromptAnswerTypes = (state: RootState) => state.promptAnswerTypes.data;
export const selectPromptAnswerTypesCounter = (state: RootState) => state.promptAnswerTypes.data.length;

export default promptAnswerTypesSlice.reducer;