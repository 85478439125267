import { ChevronDownIcon } from "@heroicons/react/20/solid";
import DocumentType from "../../../models/dto/documentType"
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

function DocumentTypeListItem({docType}: {docType: DocumentType}) {

    const navigationView = (id:string) => `/documentTypes/${id}`
    const navigationEdit = (id:string) => `/documentTypes/${id}/edit`

    return <>
        <tr key={docType.id}>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <NavLink to={navigationView(docType.id)} key={navigationView(docType.id)} className="text-indigo-600 hover:text-indigo-900">
                  <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                  <span className="sr-only">, {docType.id}</span>
              </NavLink>
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <NavLink to={navigationEdit(docType.id)} key={navigationEdit(docType.id)} className="text-indigo-600 hover:text-indigo-900">
                      <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
                      <span className="sr-only">, {docType.id}</span>
              </NavLink>
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{docType.id}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{docType.name}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{docType.playbook_id}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{docType.short_name}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{docType.description}</td>
        </tr>
    </>
}

export default function DocumentTypesList( {documentTypes}: {documentTypes: DocumentType[]}) {

    const [sorting, setSorting] = useState({ field: 'id', ascending: false })
    const [currentDocumentTypes, setCurrentDocumentTypes] = useState<DocumentType[]>(documentTypes)

    function applySorting(field: string, ascending: boolean) {
      setSorting({ field: field, ascending: ascending });
    }

    useEffect(() => {
      // Copy array to prevent data mutation
      const currentDocumentTypesCopy = [...documentTypes];
  
      // Apply sorting
      const sortedDocumentTypes = currentDocumentTypesCopy.sort((d1: DocumentType, d2: DocumentType) => {
        const field: string = sorting.field
        const d1_m = d1.toDict()
        const d2_m = d2.toDict()
        const d1_m_field = `${d1_m.get(field)}`
        const d2_m_field = `${d2_m.get(field)}`
        return d1_m_field.localeCompare(d2_m_field, undefined, { numeric: true, sensitivity: 'base'});
      });
  
      // Replace currentUsers with sorted currentUsers
      setCurrentDocumentTypes(
        // Decide either currentUsers sorted by ascending or descending order
        sorting.ascending ? sortedDocumentTypes : sortedDocumentTypes.reverse()
      );
    }, [sorting, documentTypes]);

    return (<div className="mt-8 mb-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">View</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      <a href="#" className="group inline-flex" onClick={()=>applySorting("id", !sorting.ascending)}>
                          Id
                          <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                      </a>
                  </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("name", !sorting.ascending)}>
                    Document Type
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <a href="#" className="group inline-flex" onClick={()=>applySorting("playbook_id", !sorting.ascending)}>
                          Playbook Id
                          <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                      </a>
                  </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("short_name", !sorting.ascending)}>
                    Short Name
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("description", !sorting.ascending)}>
                    Description
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
            {
                currentDocumentTypes.map(
                    (dType: DocumentType) => (
                        <DocumentTypeListItem key={dType.id} docType={dType}/>
                    )
                )
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>)

}