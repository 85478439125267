
import PromptTemplate from "../models/dto/promptTemplate";

export async function fetchPromptsTemplates(supabase: any) {

    let { data, error, status } = await supabase
        .from('prompts_templates')
        .select(`id, name, meta_template, introduction, warning, instructions,
         conditional_information, dynamic_conditional_information,
          few_shots, space, final_space, custom_template`)

    console.log("Prompt Templates Results:", data)

    if (error && status !== 406) {
        throw error
    }

    let promptsTemplates: Array<PromptTemplate> = []
    if (data) {
        promptsTemplates = data.map(
            function(pt: any) {
                const prompt_template = new PromptTemplate(
                    pt.id,
                    pt.name,
                    pt.meta_template,
                    pt.introduction,
                    pt.warning,
                    pt.instructions,
                    pt.conditional_information,
                    pt.dynamic_conditional_information,
                    pt.few_shots,
                    pt.space,
                    pt.final_space,
                    pt.custom_template
                )
                return prompt_template
            }
        )
    }

    return { promptsTemplates, error, status }
}


export async function insertPromptTemplate(supabase: any, promptTemplate: PromptTemplate): Promise<PromptTemplate> {
    
    const { data, error } = await supabase
        .from('prompts_templates')
        .insert({ 
            name: promptTemplate.name,
            meta_template: promptTemplate.meta_template,
            introduction: promptTemplate.metaItems["introduction"],
            warning: promptTemplate.metaItems["warning"],
            instructions: promptTemplate.metaItems["instructions"],
            conditional_information: promptTemplate.metaItems["conditional_information"],
            dynamic_conditional_information: promptTemplate.metaItems["dynamic_conditional_information"],
            few_shots: promptTemplate.metaItems["few_shots"],
            space: promptTemplate.metaItems["space"],
            final_space: promptTemplate.metaItems["final_space"],
            custom_template: promptTemplate.custom_template
        })
        .select()
        .single()
    
    const prompt_template = createPromptTemplateObject(data)

    return prompt_template
}

export async function updatePromptTemplate(supabase: any, promptTemplate: PromptTemplate): Promise<PromptTemplate> {
    
    const { data, error } = await supabase
        .from('prompts_templates')
        .update({
            name: promptTemplate.name,
            meta_template: promptTemplate.meta_template,
            introduction: promptTemplate.metaItems["introduction"],
            warning: promptTemplate.metaItems["warning"],
            instructions: promptTemplate.metaItems["instructions"],
            conditional_information: promptTemplate.metaItems["conditional_information"],
            dynamic_conditional_information: promptTemplate.metaItems["dynamic_conditional_information"],
            few_shots: promptTemplate.metaItems["few_shots"],
            space: promptTemplate.metaItems["space"],
            final_space: promptTemplate.metaItems["final_space"],
            custom_template: promptTemplate.custom_template,
        })
        .eq('id', promptTemplate.id)
        .select()
        .single()

    return data
  }

  export async function deletePromptTemplate(supabase: any, prompt_template: PromptTemplate): Promise<PromptTemplate> {
    
    const { data, error } = await supabase
        .from('prompts_templates')
        .delete()
        .eq('id', prompt_template.id)

    return prompt_template
  }

function createPromptTemplateObject(data:any){
    return new PromptTemplate(
        data.id,
        data.name,
        data.meta_template,
        data.introduction,
        data.warning,
        data.instructions,
        data.conditional_information,
        data.dynamic_conditional_information,
        data.few_shots,
        data.space,
        data.final_space,
        data.custom_template
    )
}