export const customTheme = {
    default: {
        colors: {
            brand: '#2E6DB4',
            brandAccent: '#81a7d2',
            brandButtonText: 'white',
            defaultButtonBackground: 'white',
            defaultButtonBackgroundHover: '#eaeaea',
            defaultButtonBorder: 'lightgray',
            defaultButtonText: 'gray',
            dividerBackground: '#eaeaea',
            inputBackground: 'transparent',
            inputBorder: 'lightgray',
            inputBorderHover: 'gray',
            inputBorderFocus: 'gray',
            inputText: 'black',
            inputLabelText: 'gray',
            inputPlaceholder: 'darkgray',
            messageText: 'gray',
            messageTextDanger: 'red',
            anchorTextColor: 'gray',
            anchorTextHoverColor: 'darkgray',
          },
          space: {
            spaceSmall: '4px',
            spaceMedium: '8px',
            spaceLarge: '16px',
            labelBottomMargin: '8px',
            anchorBottomMargin: '4px',
            emailInputSpacing: '4px',
            socialAuthSpacing: '4px',
            buttonPadding: '10px 15px',
            inputPadding: '10px 15px',
          },
          fontSizes: {
            baseBodySize: '13px',
            baseInputSize: '14px',
            baseLabelSize: '14px',
            baseButtonSize: '14px',
          },
          fonts: {
            bodyFontFamily: `ui-sans-serif, sans-serif`,
            buttonFontFamily: `ui-sans-serif, sans-serif`,
            inputFontFamily: `ui-sans-serif, sans-serif`,
            labelFontFamily: `ui-sans-serif, sans-serif`,
          },
          // fontWeights: {},
          // lineHeights: {},
          // letterSpacings: {},
          // sizes: {},
          borderWidths: {
            buttonBorderWidth: '1px',
            inputBorderWidth: '1px',
          },
          // borderStyles: {},
          radii: {
            borderRadiusButton: '4px',
            buttonBorderRadius: '4px',
            inputBorderRadius: '4px',
          },
    },
    dark: {
      colors: {
        brand: 'white',
        brandAccent: '#afafaf',
        brandButtonText: 'black',
        defaultButtonBackground: '#080808',
        defaultButtonBorder: 'black',
        defaultButtonText: 'white',
        dividerBackground: 'black',
        inputBackground: 'transparent',
        inputBorder: 'gray',
        inputText: 'black',
        inputPlaceholder: 'darkgray',
      },
    },
}