import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import { redirect, Form, useParams} from "react-router-dom";
import { useAppSelector } from '../../../redux/hooks';
import PromptTemplate from '../../../models/dto/promptTemplate';
import EditPromptTemplateForm from '../../../components/forms/promptsTemplates/editPromptsTemplateForm';
import { selectPromptsTemplates } from '../../../redux/reducers/promptsTemplatesReducer';

export default function EditPrompt() {

  let {promptTemplateId} = useParams<{promptTemplateId: string}>()

  const [promptTemplate, setPromptTemplate] = useState<PromptTemplate>()

  const promptTemplates = useAppSelector(selectPromptsTemplates)
  const oldPromptTemplate = promptTemplates.filter((pt) => pt.id === promptTemplateId)[0]  

  return <>
      <h1 className="text-3xl font-bold">Prompt Template Edition Form</h1>
      <EditPromptTemplateForm setPromptTemplate={setPromptTemplate} promptTemplate={oldPromptTemplate}/>
      <GoBackButton text={"Cancel"}/>
  </>

}
