export default class PromptTemplate{
    id: string;
    name: string;
    meta_template: string;
    /*introduction: string;
    warning: string;
    instructions: string;
    conditional_information: string;
    dynamic_conditional_information: string;
    few_shots: string[];
    space: string;
    final_space: string;*/
    custom_template: string;
    metaItems: any;

    constructor(    
        id: string,
        name: string,
        meta_template: string,
        introduction: string,
        warning: string,
        instructions: string,
        conditional_information: string,
        dynamic_conditional_information: string,
        few_shots: string[],
        space: string,
        final_space: string,
        custom_template: string
    ){
        this.id = id
        this.name = name
        this.meta_template = meta_template
        this.metaItems = {}
        this.metaItems["introduction"] = introduction
        this.metaItems["warning"] = warning
        this.metaItems["instructions"] = instructions
        this.metaItems["conditional_information"] = conditional_information
        this.metaItems["dynamic_conditional_information"] = dynamic_conditional_information
        this.metaItems["few_shots"] = few_shots
        this.metaItems["space"] = space
        this.metaItems["final_space"] = final_space
        this.custom_template = custom_template
    }

    public static getFields() {
        return [
            "introduction",
            "warning",
            "instructions",
            "conditional_information",
            "dynamic_conditional_information"
        ]
    }

    getTemplateTextSimplified(): string {
        if (this.custom_template !== ""){
            return this.custom_template
        }
        let finalTemplatePrompt = ""
        const metaItemsOrderedList = this.meta_template.split("|")
        for (let metaItem of metaItemsOrderedList){
            finalTemplatePrompt += this.metaItems[metaItem]
        }
        return finalTemplatePrompt
    }

    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["name", this.name],
            ["meta_template", this.meta_template],
            ["custom_template", this.custom_template],
            ["introduction", this.metaItems["introduction"]],
            ["warning", this.metaItems["warning"]],
            ["instructions", this.metaItems["instructions"]],
            ["conditional_information", this.metaItems["conditional_information"]],
            ["dynamic_conditional_information", this.metaItems["dynamic_conditional_information"]],
            ["space", this.metaItems["space"]],
            ["final_space", this.metaItems["final_space"]],
        ])
    }

}