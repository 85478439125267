export default class DocumentType{
    id: string;
    description: string;
    name: string;
    short_name: string;
    playbook_id: string;

    constructor(    
        description: string,
        name: string,
        short_name: string,
        playbook_id: string
    ){
        this.id = "-1";
        this.description = description;
        this.name = name;
        this.short_name = short_name;
        this.playbook_id = playbook_id;
    }

    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["description", this.description],
            ["name", this.name],
            ["short_name", this.short_name],
            ["playbook_id", this.playbook_id]
        ])
    }
}