import React, { useState } from 'react'
import { GoBackButton } from '../../../utils'
import DocumentType from '../../../models/dto/documentType';
import { useParams} from "react-router-dom";
import EditDocumentTypeForm from '../../../components/forms/documentType/editDocumentTypeForm';
import { useAppSelector } from '../../../redux/hooks';
import { selectDocumentTypes } from '../../../redux/reducers/documentTypesReducer';

export default function EditDocumentType() {

  let {documentTypeId} = useParams<{documentTypeId: string}>();

  const documentTypes = useAppSelector(selectDocumentTypes)
  const oldDocumentType = documentTypes.filter((d) => d.id === documentTypeId)[0]

  const [newdocumentType, setDocumentType] = useState<DocumentType>();

  return <>
      <h1 className="text-3xl font-bold">Document Type Edition Form</h1>
      <EditDocumentTypeForm setDocumentType={setDocumentType} documentType={oldDocumentType}/>
      <GoBackButton text={"Cancel"}/>
  </>

}
