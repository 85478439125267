
import PromptType from "../models/dto/promptType";

export async function fetchPromptTypes(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('prompt_type')
      .select(`id, prompt_type`)

    if (error && status !== 406) {
        throw error
    }

    let prompt_types: Array<PromptType> = []
    if (data) {
        prompt_types = data.map(
            function(t: any) {
                const prompt_type = new PromptType(
                    String(t.id),
                    t.prompt_type, 
                )
                return prompt_type
            }
        )
    }

    return { prompt_types, error, status }
  }