import Prompt from "../../models/dto/prompt"

interface RdxPromptsState {
    data: Prompt[],
    filter: string,
    loading: boolean,
    error: unknown
}

const promptsInitialState: RdxPromptsState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxPromptsState
}
export {
    promptsInitialState
}
