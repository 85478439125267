import { NavLink} from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useAppSelector } from "../../../redux/hooks";
import { selectPromptsTemplates } from '../../../redux/reducers/promptsTemplatesReducer';
import PromptsTemplatesList from './promptsTemplatesList';
import { CreateButton } from '../../../components/buttons';

export default function PromptsTemplatesPage() {

    let {documentTypeId} = useParams<{documentTypeId: string}>();

    const promptsTemplates = useAppSelector(selectPromptsTemplates);

    return <>
        <h1 className="text-3xl font-bold mb-5">Prompts Templates Page</h1>
        <NavLink to={`/promptsTemplates/new`}>
            <CreateButton text="Add Prompt Template"/>
        </NavLink>
        <PromptsTemplatesList promptsTemplates={promptsTemplates}/>
    </>
}