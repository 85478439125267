import Category from "../models/dto/category";

export async function fetchCategories(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('categories')
      .select(`id, category_name, description`)

    if (error && status !== 406) {
        throw error
    }

    let categories: Array<Category> = []
    if (data) {
        categories = data.map(
            function(c: any) {
                const category = new Category(c.category_name, c.description)
                category.id = String(c.id)
                return category
            }
        )
    }

    return { categories, error, status }
  }

  export async function insertCategory(supabase: any, category: Category) {
    
    const { data, error } = await supabase
        .from('categories')
        .insert({ 
            description: category.description,
            category_name: category.name,
        })
        .select()

    return createCategoryObject(data)
  }

  export async function updateCategory(supabase: any, category: Category) {
    
    const { data, error } = await supabase
        .from('categories')
        .update({
            description: category.description,
            category_name: category.name,
        })
        .eq('id', Number(category.id))
        .select()

    return createCategoryObject(data)
  }

  export async function deleteCategory(supabase: any, category: Category) {
    
    const { data, error } = await supabase
        .from('categories')
        .delete()
        .eq('id', Number(category.id))

    return category
  }

function createCategoryObject(data:any){
    const category = new Category(data.category_name, data.description)
    category.id = String(data.id)
    return category
}