import { Session, SupabaseClient } from "@supabase/auth-helpers-react"

function isUserInWhitelist(session: Session|null, supabase: SupabaseClient) {
    if (!session){
        return false
    }

    const getUserData = async () => {
        try {
          const { user } = session
    
          /*let { data, error, status } = await supabase
            .from('profiles')
            .select(`id, username, email, whitelist_internal_user(email)`)
            .eq('id', user.id)
            .single()*/
          console.log("before user:", user)
          let { data, error, status } = await supabase
            .from('whitelist_internal_user')
            .select(`id`)
            .eq('email', user.email)
            .single()
    
          if (error && status !== 406) {
            throw error
          }
          console.log("uuser:", user)
          console.log("in user data:", data)
          if (data) {
            console.log("returning true")
            return true
          }
          return false
        } catch (error: any) {
          alert(error.message)
          return false
        }
    }

    const result = getUserData()
    return result

}

export { isUserInWhitelist }