import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Tag from "../../../models/dto/tag";
import TagsListItem from "./tagsListItem";
import { useEffect, useState } from "react";

export default function TagsList( {tags}: {tags: Array<Tag>}) {

    const [sorting, setSorting] = useState({ field: 'id', ascending: false })
    const [currentTags, setCurrentTags] = useState<Tag[]>(tags)

    function applySorting(field: string, ascending: boolean) {
      setSorting({ field: field, ascending: ascending });
    }

    useEffect(() => {
      // Copy array to prevent data mutation
      const currentTagsCopy = [...tags];
  
      // Apply sorting
      const sortedTags = currentTagsCopy.sort((t1: Tag, t2: Tag) => {
        const field: string = sorting.field
        const t1_m = t1.toDict()
        const t2_m = t2.toDict()
        const t1_m_field = `${t1_m.get(field)}`
        const t2_m_field = `${t2_m.get(field)}`
        return t1_m_field.localeCompare(t2_m_field, undefined, { numeric: true, sensitivity: 'base'});
      });
  
      // Replace currentUsers with sorted currentUsers
      setCurrentTags(
        // Decide either currentUsers sorted by ascending or descending order
        sorting.ascending ? sortedTags : sortedTags.reverse()
      );
    }, [sorting]);

    return (
    <div className="mt-8 mb-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        <a href="#" className="group inline-flex" onClick={()=>applySorting("id", !sorting.ascending)}>
                            Tag Id
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        </a>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <a href="#" className="group inline-flex" onClick={()=>applySorting("name", !sorting.ascending)}>
                            Tag Name
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        </a>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <a href="#" className="group inline-flex" onClick={()=>applySorting("description", !sorting.ascending)}>
                            Description
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        </a>
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <a href="#" className="group inline-flex" onClick={()=>applySorting("categoryId", !sorting.ascending)}>
                            Category Id
                            <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                        </a>
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">View</span>
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {
                        currentTags.map(
                            (tag: Tag) => (
                                <TagsListItem key={tag.id} tag={tag}/>
                            )
                        )
                    }
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>
  )

}