
import StatsDashboard from "../../components/statsDashboard/statsDashboard"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchDocumentTypesAsync, selectDocumentTypes, selectDocumentTypesCounter } from "../../redux/reducers/documentTypesReducer";
import { fetchPromptsAsync, selectPrompts, selectPromptsCounter } from "../../redux/reducers/promptsReducer";
import { fetchFollowUpsAsync, selectFollowUps, selectFollowUpsCounter } from "../../redux/reducers/followUpsReducer";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { fetchCategoriesAsync, selectCategories, selectCategoriesCounter } from "../../redux/reducers/categoriesReducer";
import { fetchTagsAsync, selectTags, selectTagsCounter } from "../../redux/reducers/tagsReducer";
import { fetchPromptsTemplatesAsync, selectPromptsTemplatesCounter } from "../../redux/reducers/promptsTemplatesReducer";
import { fetchPromptTypesAsync } from "../../redux/reducers/promptsTypesReducer";
import { fetchPromptAnswerTypesAsync } from "../../redux/reducers/promptsAnswerTypesReducer";

import { EyeIcon } from '@heroicons/react/24/outline'
import classNames from "classnames";
import { NavLink } from "react-router-dom";

export default function Home() {

    const session = useSession()
    const supabase = useSupabaseClient()

    const dispatch = useAppDispatch();

    const counterDocTypes = useAppSelector(selectDocumentTypesCounter)
    const counterPrompts = useAppSelector(selectPromptsCounter)
    const counterFollowUps = useAppSelector(selectFollowUpsCounter)
    const counterTags = useAppSelector(selectTagsCounter)
    const counterCategories = useAppSelector(selectCategoriesCounter)
    const counterPromptTemplates = useAppSelector(selectPromptsTemplatesCounter)

    useEffect(() => {
        const getAll = () => {
            getDocumentTypes();
            getPrompts();
            getFollowUps();
            getTags();
            getCategories();
            getPromptTemplates();
            getPromptTypes();
            getPromptAnswerTypes();
        }
        getAll();
      }, [session])

    const getDocumentTypes = async () => {
        try {
            dispatch(fetchDocumentTypesAsync(supabase))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getPrompts = async () => {
        try {
            const documentTypeName = null
            dispatch(fetchPromptsAsync({supabase, documentTypeName}))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getFollowUps = async () => {
        try {
            const promptId = null
            dispatch(fetchFollowUpsAsync({supabase, promptId}))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getTags = async () => {
        try {
            dispatch(fetchTagsAsync(supabase))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getCategories = async () => {
        try {
            dispatch(fetchCategoriesAsync(supabase))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getPromptTemplates = async () => {
        try {
            dispatch(fetchPromptsTemplatesAsync({supabase}))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getPromptTypes = async () => {
        try {
            dispatch(fetchPromptTypesAsync({supabase}))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const getPromptAnswerTypes = async () => {
        try {
            dispatch(fetchPromptAnswerTypesAsync({supabase}))
        } catch (error: any) {
            alert(error.message)
        }
    }

    const stats = [
        {id: "documentTypes", label: "Total Document Types", counter: counterDocTypes, icon: EyeIcon},
        {id: "prompts", label: "Total Prompts", counter: counterPrompts, icon: EyeIcon},
        {id: "followUps", label: "Total FollowUps", counter: counterFollowUps, icon: EyeIcon},
        {id: "tags", label: "Total Tags", counter: counterTags, icon: EyeIcon},
        {id: "categories", label: "Total Categories", counter: counterCategories, icon: EyeIcon},
        {id: "promptsTemplates", label: "Total Prompts Templates", counter: counterPromptTemplates,  icon: EyeIcon}
    ]


    return (<>
        <div className="flex items-center justify-center flex-col">
            <h1 className="text-3xl font-bold mb-8">Legalreview.ai Internal App</h1>
        </div>
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((item) => (
                <div
                    key={item.id}
                    className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                    <div className="absolute rounded-md bg-indigo-500 p-3">
                        <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.label}</p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                    <p className="text-2xl font-semibold text-gray-900">{item.counter}</p>
                    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                        <NavLink to={"/"+item.id} key={'home'} className="font-medium text-indigo-600 hover:text-indigo-500">
                            {' '}
                            View all<span className="sr-only"> {item.label} stats</span>
                        </NavLink>
                        </div>
                    </div>
                    </dd>
                </div>
                ))}
            </dl>
        </div>
    </>
    )

}
