export default class Playbook{
    id: string;
    description: string;
    name: string;
    short_name: string;
    active: boolean;

    constructor(    
        description: string,
        name: string,
        short_name: string,
        active: boolean
    ){
        this.id = "-1";
        this.description = description;
        this.name = name;
        this.short_name = short_name;
        this.active = active;
    }

    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["description", this.description],
            ["name", this.name],
            ["short_name", this.short_name],
            ["active", this.active]
        ])
    }
}