export default class Category{
    id: string;
    name: string;
    description: string;

    constructor(    
        name: string,
        description: string,
    ){
        this.id = "-1";
        this.name = name;
        this.description = description;
    }
    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["name", this.name],
            ["description", this.description]
        ])
    }
}