import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const popUpConfirmAlert = (title: string, message: string, yesAction: any, noAction: any) => {
    confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => yesAction()
      },
      {
        label: 'No',
      }
    ]
  })
}