

export default class FollowUp{
    id: string;
    originPromptId: string;
    triggerAnswer: string;
    riskScore: number;
    targetPromptId: string;
    note: string;
    active: boolean;

    constructor(    
        originPromptId: string,
        triggerAnswer: string,
        riskScore: number,
        targetPromptId: string,
        note: string,
        active: boolean
    ){
        this.id = "-1";
        this.originPromptId = originPromptId;
        this.triggerAnswer = triggerAnswer;
        this.riskScore = riskScore;
        this.targetPromptId = targetPromptId;
        this.note = note;
        this.active = active;
    }

    isActiveStr(): string {
        return this.active ? "Yes" : "No"
    }

    toDict(){
        return new Map<string, any>([
            ["id", this.id],
            ["originPromptId", this.originPromptId],
            ["triggerAnswer", this.triggerAnswer],
            ["riskScore", this.riskScore],
            ["targetPromptId", this.targetPromptId],
            ["note", this.note],
            ["active", this.active]
        ])
    }
}