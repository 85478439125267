import { NavLink, useNavigate } from "react-router-dom";
import PromptTemplate from "../../../models/dto/promptTemplate";

export default function PromptsTemplatesListItem({promptTemplate}: {promptTemplate: PromptTemplate}) {
    
    const navigationView = (id:string) => `/promptsTemplates/${id}`
    const navigationEdit = (id:string) => `/promptsTemplates/${id}/edit`

    return <>
        <tr key={promptTemplate.id}>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationView(promptTemplate.id)} key={navigationView(promptTemplate.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                <span className="sr-only">, {promptTemplate.id}</span>
            </NavLink>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <NavLink to={navigationEdit(promptTemplate.id)} key={navigationEdit(promptTemplate.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Edit</span>
                <span className="sr-only">, {promptTemplate.id}</span>
        </NavLink>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{promptTemplate.id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.name}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.meta_template}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.custom_template}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["introduction"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["warning"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["instructions"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["conditional_information"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["dynamic_conditional_information"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["space"]}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{promptTemplate.metaItems["final_space"]}</td>
    </tr></>
}