import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import Prompt from '../../../models/dto/prompt';
import PromptTemplateForm from '../../../components/forms/promptsTemplates/promptsTemplateForm';

export default function CreatePromptTemplate() {
    const [promptTemplate, setPromptTemplate] = useState<Prompt>();

    return <>
        <h1 className="text-3xl font-bold mb-10">Prompt Template Creation Form</h1>
        <PromptTemplateForm 
            setPromptTemplate={setPromptTemplate}
        />
        <GoBackButton text={"Cancel"}/>
    </>

}
