import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import { redirect, Form} from "react-router-dom";
import TagForm from '../../../components/forms/tag/tagForm';
import Tag from '../../../models/dto/tag';

export default function CreateTag() {
    const [tag, setTag] = useState<Tag>();

    return <>
        <h1 className="text-3xl font-bold">Tag Creation Form</h1>
        <TagForm setTag={setTag}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
