import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { NavLink } from "react-router-dom";

export default function AuthHeaderComponent() {

    const session = useSession()
    const supabase = useSupabaseClient()

    async function signout() {
        const { error } = await supabase.auth.signOut()
      }

    return session ? 
        <button onClick={signout} type="button" className="ml-5 inline-flex items-center rounded border border-red-300 bg-white px-2.5 py-1.5 text-xs font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <NavLink to={'/auth'} key={'logout'}>
                    Log Out
            </NavLink>
        </button>
        :
        <>
            <button type="button" className="inline-flex items-center rounded border border-green-300 bg-white px-2.5 py-1.5 text-xs font-medium text-green-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <NavLink to={'/auth'} key={'login'}>
                    Log In
                </NavLink>
            </button>

            <button type="button" className="inline-flex items-center rounded border border-blue-300 bg-white px-2.5 py-1.5 text-xs font-medium text-blue-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <NavLink to={'/auth'} key={'logout'}>
                    Sign In
                </NavLink>
            </button>
        </>

}