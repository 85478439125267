import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { removeFollowUp, selectFollowUps } from "../../../redux/reducers/followUpsReducer";
import FollowUpInfo from "./followUpInfo";
import PromptsList from "../promptsViews/promptsList";
import { selectPrompts } from "../../../redux/reducers/promptsReducer";
import FollowUp from "../../../models/dto/followUp";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { deleteFollowUp } from "../../../database/followUpQueries";
import Divider from "../../../components/dividers";
import { CreateButton, DeleteButton, EditButton } from "../../../components/buttons";
import { popUpConfirmAlert } from "../../../components/confirmPopUp";
import { pushNotificationMessage } from "../../../components/notifcationMessage";

export default function FollowUpDetail() {
    let {followUpId} = useParams<{followUpId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    console.log("followUpId: ", followUpId)
    
    const followUps = useAppSelector(selectFollowUps)
    const followUp = followUps.filter((f) => f.id === followUpId)[0]

    const prompts = useAppSelector(selectPrompts);

    const originPrompts = prompts.filter((f) => f.prompt_id === followUp.originPromptId)

    const targetPrompts = prompts.filter((f) => f.prompt_id ===followUp.targetPromptId)

    const delFollowUp = async () => {
        const deletedFollowUp: FollowUp = await deleteFollowUp(supabase, followUp)

        if (!deletedFollowUp) {
            pushNotificationMessage("There was an issue while deleting Follow Up", "error")
            return 
        }
        dispatch(removeFollowUp(followUpId ? followUpId : ""))
        pushNotificationMessage("Follow Up was deleted with Success", "success")
        navigate("/followUps")
    }

    return <>
        <FollowUpInfo followUp={followUp}/>
        <div className="inline-flex items-center mt-2 mb-2">
            <NavLink to={`/followUps/${followUpId}/edit`}>
                <EditButton text="Edit Follow Up"/>
            </NavLink>
            <div className='ml-5'>
                <DeleteButton text="Delete Follow Up" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Follow Up.",
                    `Are you sure you want to delete Follow Up: ${followUpId}? This Action cannot be undone.`,
                    delFollowUp,
                    () => {}
                )}/>
            </div>
        </div>

        <Divider text="Prompts Related"/>

        <h1 className="text-2xl font-bold mb-5">Origin Prompt Information</h1>
        <PromptsList prompts={originPrompts}/>

        <Divider text=""/>

        <h1 className="text-2xl font-bold mb-5">Target Prompt Information</h1>
        <PromptsList prompts={targetPrompts}/>
    </>
}