export default class PromptAnswerType{
    id: string;
    answerType: string;

    constructor(    
        id: string,
        answerType: string
    ){
        this.id = id;
        this.answerType = answerType;
    }
}