import Prompt from "../../../models/dto/prompt";
import { useState, useEffect } from "react";
import { NavLink} from 'react-router-dom'
import { useAppSelector } from "../../../redux/hooks";
import { selectTags } from "../../../redux/reducers/tagsReducer";
import TagsList from "./tagsList";
import { CreateButton } from "../../../components/buttons";

export default function TagsPage() {

    const tags = useAppSelector(selectTags);

    return <>
        <h1 className="text-3xl font-bold mb-5">Tags Page</h1>
        <NavLink to={`/tags/new`}>
            <CreateButton text="Add Tag"/>
        </NavLink>
        <TagsList tags={tags}/>
    </>
}