import { Route, Routes } from "react-router-dom";
import FollowUpsPage from './body/followUpsViews/followUpsPage';
import DocumentTypesPage from './body/documentTypeViews/documentTypePage';
import CreatePrompt from './body/promptsViews/createPrompt';
import CreateFollowUp from './body/followUpsViews/createFollowUp';
import FollowUpDetail from './body/followUpsViews/followUpDetail';
import TagsPage from './body/tagsViews/tagsPage';
import CreateTag from './body/tagsViews/createTag';
import TagDetail from './body/tagsViews/tagDetail';
import EvaluatePage from './body/evaluateViews/evaluatePage';
import EditDocumentType from './body/documentTypeViews/editDocumentType';
import EditPrompt from './body/promptsViews/editPrompt';
import EditFollowUp from './body/followUpsViews/editFollowUp';
import EditTag from './body/tagsViews/editTag';
import PromptsTemplatesPage from './body/promptsTemplatesViews/promptsTemplatesPage';
import CreatePromptTemplate from './body/promptsTemplatesViews/createPromptTemplate';
import EditPromptTemplate from './body/promptsTemplatesViews/editPromptTemplate';
import PromptTemplateDetail from './body/promptsTemplatesViews/promptTemplateDetail';
import Home from "./body/home";
import CreateDocumentType from "./body/documentTypeViews/createDocumentType";
import DocumentTypeDetail from "./body/documentTypeViews/documentTypeDetail";
import PromptsPage from "./body/promptsViews/promptsPage";
import PromptDetail from "./body/promptsViews/promptDetail";
import { CustomAuth } from "../components/security/customAuth";
import AppFooter from "./footer/footer";
import { GoBackButton } from "../utils";
import TestDocumentTypesPage from "./body/testDocumentTypesViews/testDocumentTypesPage";
import PlaybooksPage from "./body/playbookViews/playbookPage";
import CreatePlaybook from "./body/playbookViews/createPlaybook";
import PlaybookDetail from "./body/playbookViews/playbookDetail";
import EditPlaybook from "./body/playbookViews/editPlaybook";
import AnalysisPage from "./body/analysisViews/analysisPage";
import JobCommentsPage from "./body/analysisViews/jobCommentsPage";
import FeedbacksPage from "./body/analysisViews/feedbackPage";

export default function AppMain() {

    return (
        <div className="flex-grow">
            <GoBackButton text={"Back"}/>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/auth" element={<CustomAuth/>}/>
              <Route path="/playbooks/:playbookId/prompts/:promptId" element={<PromptDetail/>} />
              <Route path="/playbooks/:playbookId/prompts/new" element={<CreatePrompt/>} />

              <Route path="/playbooks/:playbookId/prompts/:promptId/followUps" element={<FollowUpsPage/>}/>
              <Route path="/playbooks/:playbookId/prompts/:promptId/followUps/new" element={<CreateFollowUp/>}/>
              <Route path="/playbooks/:playbookId/prompts/:promptId/followUps/:followUpId" element={<FollowUpDetail/>}/>

              <Route path="/documentTypes" element={<DocumentTypesPage/>}/>
              <Route path="/documentTypes/new" element={<CreateDocumentType/>}/>
              <Route path="/documentTypes/:documentTypeId" element={<DocumentTypeDetail/>} />
              <Route path="/documentTypes/:documentTypeId/edit" element={<EditDocumentType/>} />

              <Route path="/playbooks" element={<PlaybooksPage/>}/>
              <Route path="/playbooks/new" element={<CreatePlaybook/>}/>
              <Route path="/playbooks/:playbookId" element={<PlaybookDetail/>} />
              <Route path="/playbooks/:playbookId/edit" element={<EditPlaybook/>} />

              <Route path="/prompts" element={<PromptsPage/>}/>
              <Route path="/prompts/new" element={<CreatePrompt/>}/>
              <Route path="/prompts/:promptId" element={<PromptDetail/>} />
              <Route path="/prompts/:promptId/edit" element={<EditPrompt/>} />
              <Route path="/prompts/:promptId/followUps/new" element={<CreateFollowUp/>} />

              <Route path="/followUps" element={<FollowUpsPage/>}/>
              <Route path="/followUps/new" element={<CreateFollowUp/>} />
              <Route path="/followUps/:followUpId" element={<FollowUpDetail/>} />
              <Route path="/followUps/:followUpId/edit" element={<EditFollowUp/>} />

              <Route path="/tags" element={<TagsPage/>}/>
              <Route path="/tags/new" element={<CreateTag/>} />
              <Route path="/tags/:tagId" element={<TagDetail/>} />
              <Route path="/tags/:tagId/edit" element={<EditTag/>} />

              <Route path="/evaluate" element={<EvaluatePage/>}/>
              <Route path="/testDocumentTypes" element={<TestDocumentTypesPage/>}/>

              <Route path="/promptsTemplates" element={<PromptsTemplatesPage/>}/>
              <Route path="/promptsTemplates/new" element={<CreatePromptTemplate/>}/>
              <Route path="/promptsTemplates/:promptTemplateId" element={<PromptTemplateDetail/>}/>
              <Route path="/promptsTemplates/:promptTemplateId/edit" element={<EditPromptTemplate/>}/>

              <Route path="/analysis" element={<AnalysisPage/>}/>
              <Route path="/jobComments/:jobId" element={<JobCommentsPage/>}/>

              <Route path="/comments_feedback" element={<FeedbacksPage/>}/>

            </Routes>
            <AppFooter/>
          </div>
    )
}