
import Tag from "../models/dto/tag";

export async function fetchTags(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('tags')
      .select(`tag_id, tag_name, description, category_id`)

    if (error && status !== 406) {
        throw error
    }

    let tags: Array<Tag> = []
    if (data) {
        tags = data.map(
            function(t: any) {
                return createTagObject(t)
            }
        )
    }

    return { tags, error, status }
  }

  export async function insertTag(supabase: any, tag: Tag): Promise<Tag> {

    const cat_id = tag.categoryId === "" || tag.categoryId === "null" ? null : Number(tag.categoryId)
    const { data, error } = await supabase
        .from('tags')
        .insert({
            tag_id: tag.id,
            description: tag.description,
            tag_name: tag.name,
            category_id: cat_id
        })
        .select()
        .single()

    return createTagObject(data)
  }

  export async function updateTag(supabase: any, tag: Tag): Promise<Tag> {

    const cat_id = tag.categoryId === "" || tag.categoryId === "null" ? null : Number(tag.categoryId)
    const { data, error } = await supabase
        .from('tags')
        .update({
            description: tag.description,
            tag_name: tag.name,
            category_id: cat_id
        })
        .eq('tag_id', tag.id)
        .select()
        .single()

    return createTagObject(data)
  }

  export async function deleteTag(supabase: any, tag: Tag): Promise<Tag> {
    
    const { data, error } = await supabase
        .from('tags')
        .delete()
        .eq('tag_id', tag.id)

    return tag
  }

function createTagObject(data:any){
    const tag = new Tag(data.tag_name, data.description, data.category_id)
    tag.id = String(data.tag_id)
    return tag
}