import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import TagInfo from "./tagInfo";
import { removeTag, selectTags } from "../../../redux/reducers/tagsReducer";
import { selectPrompts } from "../../../redux/reducers/promptsReducer";
import PromptsList from "../promptsViews/promptsList";
import Tag from "../../../models/dto/tag";
import { deleteTag } from "../../../database/tagsQueries";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { CreateButton, DeleteButton, EditButton } from "../../../components/buttons";
import Divider from "../../../components/dividers";
import { popUpConfirmAlert } from "../../../components/confirmPopUp";
import { pushNotificationMessage } from "../../../components/notifcationMessage";

export default function TagDetail() {
    let {tagId} = useParams<{tagId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    const tags = useAppSelector(selectTags);
    const tag = tags.filter((t) => t.id === tagId)[0]

    const prompts = useAppSelector(selectPrompts);
    const filteredPrompts = prompts.filter((p) => p.tag_id === tagId)

    console.log("filteredPrompts:", filteredPrompts)

    const delTag = async () => {
        const deletedTag: Tag = await deleteTag(supabase, tag)

        if (!deletedTag) {
            pushNotificationMessage("There was an issue while deleting Tag", "error")
            return 
        }
        dispatch(removeTag(tagId ? tagId : ""))
        pushNotificationMessage("Tag was deleted with Success", "success")
        navigate("/tags")
    }

    return <>
        <TagInfo tag={tag}/>

        <div className="inline-flex items-center">
            <NavLink to={`/tags/${tagId}/edit`}>
                <EditButton text="Edit Tag"/>
            </NavLink>
            <div className='ml-5'>
                <DeleteButton text="Delete Tag" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Tag.",
                    `Are you sure you want to delete Tag: ${tagId}? This Action cannot be undone.`,
                    delTag,
                    () => {}
                )}/>
            </div>
        </div>

        <Divider text="Tag Prompts"/>

        <PromptsList prompts={filteredPrompts}/>
        </>
}