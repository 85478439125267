
import FollowUp from "../models/dto/followUp";

  export async function fetchFollowUps(supabase: any, promptId: string|null) {
    let supabaseResult;
    if (promptId !== null) {
        supabaseResult = await supabase
        .from('rules')
        .select(`id, input_prompt, input_answer, output_score, output_prompt, output_note, active`)
        .eq('input_prompt', Number(promptId))
    }else{
        supabaseResult = await supabase
        .from('rules')
        .select(`id, input_prompt, input_answer, output_score, output_prompt, output_note, active`)
    }

    let { data, error, status } = supabaseResult
    
    console.log("FollowUps Results:", data)
    console.log("Error:", error)
    console.log("Status:", status)

    if (error && status !== 406) {
        throw error
    }

    let followUps: Array<FollowUp> = []
    if (data) {
        followUps = data.map(
            function(f: any) {
                return createFollowUpObject(f)
            }
        )
    }

    return { followUps, error, status }
  }

  export async function insertFollowUp(supabase: any, followUp: FollowUp): Promise<FollowUp> {
    console.log("Inserting follow up:", followUp)
    const target_prompt = followUp.targetPromptId === "" || followUp.targetPromptId === "null" ? null : Number(followUp.targetPromptId)
    const { data, error } = await supabase
        .from('rules')
        .insert({ 
            input_prompt: Number(followUp.originPromptId),
            input_answer: followUp.triggerAnswer,
            output_score: followUp.riskScore,
            output_prompt: target_prompt,
            output_note: followUp.note
        })
        .select()
        .single()

    return createFollowUpObject(data)
  }

  export async function updateFollowUp(supabase: any, followUp: FollowUp): Promise<FollowUp> {
    const target_prompt = followUp.targetPromptId === "" || followUp.targetPromptId === "null" ? null : Number(followUp.targetPromptId)
    const { data, error } = await supabase
        .from('rules')
        .update({
            input_prompt: Number(followUp.originPromptId),
            input_answer: followUp.triggerAnswer,
            output_score: followUp.riskScore,
            output_prompt: target_prompt,
            output_note: followUp.note
        })
        .eq('id', Number(followUp.id))
        .select()
        .single()

    return createFollowUpObject(data)
  }


  export async function deleteFollowUp(supabase: any, followUp: FollowUp): Promise<FollowUp> {
    
    const { data, error } = await supabase
        .from('rules')
        .delete()
        .eq('id', Number(followUp.id))

    return followUp
  }

function createFollowUpObject(data:any){
    const followUp = new FollowUp(
        String(data.input_prompt),
        data.input_answer,
        data.output_score,
        String(data.output_prompt),
        data.output_note,
        data.active
    )
    followUp.id = String(data.id)
    return followUp
}
