import {useEffect, useState} from 'react';
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {
    fetchJobDocumentElementsCommentsWithFeedback
} from "../../../database/analsyisQueries";
import {useParams} from "react-router-dom";
import CommentsList from "./commentsList";
import {parseCommentsFromDocument} from "./feedback_utils";

export default function JobCommentsPage() {
    let {jobId} = useParams<{jobId: string}>();
    let parsedJobId: number = parseInt(jobId ? jobId : "-1")
    const supabase = useSupabaseClient()

    // logic
    const [document, setDocument] = useState({})
    const [comments, setComments] = useState([])

    useEffect(() => {
        const fetch_document_data = async () => {
            console.log("parsedJobId:", parsedJobId)
            if (parsedJobId !== -1) {
                const pb = await fetchJobDocumentElementsCommentsWithFeedback(supabase, parsedJobId);
                let document = pb.data[0]
                setComments(parseCommentsFromDocument(document))
                setDocument(document)
                return pb
            }
            return {}
        };
        const _response = fetch_document_data()
        console.log(_response.then((x)=>console.log(x)))
    },[])

    return (
        <CommentsList comments={comments} jobId={parsedJobId}/>
    );
}