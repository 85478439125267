import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import Playbook from "../../../models/dto/playbook";
import PlaybookCreationForm from "../../../components/forms/playbook/playbookCreationForm";

export default function CreatePlaybook() {

    const [playbook, setPlaybook] = useState<Playbook>();

    return <>
        <h1 className="text-3xl font-bold">Playbook Creation Form</h1>
        <PlaybookCreationForm setPlaybook={setPlaybook}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
