import React, { EventHandler, useEffect, useState } from 'react'
import { Checkbox, useDropdown, useInput } from '../../../utils'
import PromptTestingPreBuiltPage from './preBuiltPromptTestingView';
import PromptTestingInteractivePage from './interactivePromptTesting';


export default function EvaluatePage() {

    const [usePreBuiltTemplate, setUsePreBuiltTemplate] = useState(true);

    const tooglePreBuiltTemplate = (e: any) => {
      setUsePreBuiltTemplate(!usePreBuiltTemplate)
  }

    return (
      <>
        <h1 className="text-3xl font-bold mb-5">Test Prompts Page</h1>
        {/*<Checkbox 
            headerText='Activate/Deactivate Pre-Built Template' 
            descriptionText='' 
            toggleAction={tooglePreBuiltTemplate} 
            currentState={usePreBuiltTemplate}
        />*/}
        <PromptTestingPreBuiltPage/>
        {/*usePreBuiltTemplate ? <PromptTestingPreBuiltPage/> : <PromptTestingInteractivePage/>*/}
      </>
    )

}
