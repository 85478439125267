import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { RdxFollowUpsState, followUpsInitialState } from '../states/followUpsState';
import { fetchFollowUps } from '../../database/followUpQueries';
import FollowUp from '../../models/dto/followUp';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchFollowUpsAsync = createAsyncThunk(
  'followUps/fetchFollowUps',
  async ({supabase, promptId}: {supabase: any, promptId: string|null}) => {
    const response = await fetchFollowUps(supabase, promptId);
    // The value we return becomes the `fulfilled` action payload
    return response.followUps;
  }
);

export const followUpsSlice = createSlice({
  name: 'followUps',
  initialState: followUpsInitialState as RdxFollowUpsState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    receiveFollowUpsList: (state: RdxFollowUpsState, action: PayloadAction<FollowUp[]>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        const followUps: FollowUp[] = action.payload;
        state.data = [...state.data, ...followUps]
    },
    addFollowUp: (state: RdxFollowUpsState, action: PayloadAction<FollowUp>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const followUp: FollowUp = action.payload
      state.data = [...state.data, followUp]
    },
    removeFollowUp: (state: RdxFollowUpsState, action: PayloadAction<string>) => {
        const promptId: string = action.payload
        const updatedDocumentTypes = state.data.filter(item => item.originPromptId !== promptId)
        state.data = updatedDocumentTypes
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    editFollowUp: (state: RdxFollowUpsState, action: PayloadAction<FollowUp>) => {
        const newFollowUp: FollowUp = action.payload
        const new_prompts = [...state.data.filter(item => item.originPromptId !== newFollowUp.originPromptId), newFollowUp]
        state.data = new_prompts
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchFollowUpsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFollowUpsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchFollowUpsAsync.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

export const { receiveFollowUpsList, addFollowUp, removeFollowUp, editFollowUp } = followUpsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFollowUps = (state: RootState) => state.followUps.data;
export const selectFollowUpsCounter = (state: RootState) => state.followUps.data.length;

export default followUpsSlice.reducer;