import React, { EventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDropdown, useInput } from '../../../utils'
import Prompt from '../../../models/dto/prompt'
import DropdownItem from '../../../models/dto/dropdownItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectTags } from '../../../redux/reducers/tagsReducer';
import Tag from '../../../models/dto/tag';
import Category from '../../../models/dto/category';
import { selectCategories } from '../../../redux/reducers/categoriesReducer';
import { selectDocumentTypes } from '../../../redux/reducers/documentTypesReducer';
import DocumentType from '../../../models/dto/documentType';
import { editPrompt } from '../../../redux/reducers/promptsReducer';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { updatePrompt } from '../../../database/promptsQueries';
import { selectPromptTypes } from '../../../redux/reducers/promptsTypesReducer';
import PromptType from '../../../models/dto/promptType';
import PromptAnswerType from '../../../models/dto/promptAnswerType';
import { selectPromptAnswerTypes } from '../../../redux/reducers/promptsAnswerTypesReducer';
import { selectPromptsTemplates } from '../../../redux/reducers/promptsTemplatesReducer';
import PromptTemplate from '../../../models/dto/promptTemplate';
import { EditButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';
import {fetchPlaybooks} from "../../../database/playbookQueries";
import Playbook from "../../../models/dto/playbook";

export default function EditPromptForm(
    {setPrompt, prompt}: {setPrompt: any, prompt: Prompt}
    ) {

    let {paramPromptId} = useParams<{paramPromptId: string}>()
    console.log("Editing promptId:", paramPromptId)

    // Document Types
    const [rawPlaybooks, setPlaybooks] = useState<Playbook[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // playbooks
    useEffect(() => {
        const fetch_playbooks = async () => {
            const pbs = await fetchPlaybooks(supabase);
            setPlaybooks(pbs.playbooks)
        };
        const _response = fetch_playbooks()
    },[])

    const dropdownPlaybooks = rawPlaybooks.map(
        (item: Playbook) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Tags
    const rawTags = useAppSelector(selectTags);
    const dropdownTags = rawTags.map(
        (item: Tag) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Categories
    const rawCategories = useAppSelector(selectCategories);
    const dropdownCategories = rawCategories.map(
        (item: Category) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    // Prompt Types
    const rawPromptTypes = useAppSelector(selectPromptTypes);
    const dropdownPromptTypes = rawPromptTypes.map(
        (item: PromptType) => new DropdownItem(item.id, item.promptType, item, item.id)
    )

    // Prompt Answer Types
    const rawPromptAnswerTypes = useAppSelector(selectPromptAnswerTypes);
    const dropdownPromptAnswerTypes = rawPromptAnswerTypes.map(
        (item: PromptAnswerType) => new DropdownItem(item.id, item.answerType, item, item.id)
    )

    // Prompt Templates
    const rawPromptTemplates = useAppSelector(selectPromptsTemplates);
    const dropdownPromptTemplates = rawPromptTemplates.map(
        (item: PromptTemplate) => new DropdownItem(item.id, item.name, item, item.id, item.meta_template)
    )

    // Status
    const dropdownStatus = [
        new DropdownItem("PROD", "PROD", "PROD"),
        new DropdownItem("TEST", "TEST", "TEST")
    ]

    const initialPlaybookName = rawPlaybooks.filter((p) => p.id === prompt.playbook_id)[0]?.name ?? "..."

    const oldTagName = rawTags.filter((t) => t.id === prompt.tag_id)[0]?.name ?? "..."
    const oldCategoryName = rawCategories.filter((c) => c.id === prompt.category_id)[0]?.name ?? "..."

    const [inputPromptId, promptId, setPromptId] = useInput(prompt.prompt_id, "Prompt Id", prompt.prompt_id, true)

    const [inputPromptText, promptText, setPromptText] = useInput(prompt.prompt_text, "Prompt Text", prompt.prompt_text)
    const [dropdownTagId, tagId, tagName, selectedTag, setTagId] = useDropdown(prompt.tag_id, "Choose Tag", dropdownTags, oldTagName)

    const [dropdownCategoryId, categoryId, categoryName, selectedCategory, setCategoryId] = useDropdown(prompt.category_id, "Choose Category", dropdownCategories, oldCategoryName)

    const oldPromptType = rawPromptTypes.filter((pt) => pt.id === prompt.prompt_type_id)[0]?.promptType ?? "..."
    const oldPromptAnswerType = rawPromptAnswerTypes.filter((pat) => pat.id === prompt.prompt_answer_type_id)[0]?.answerType ?? "..."

    const [inputPromptType, promptTypeId, promptType, selectedPromptType, setPromptTypeId] = useDropdown(prompt.prompt_type_id, "Choose Prompt Type", dropdownPromptTypes, oldPromptType)
    const [inputPromptAnswerType, promptAnswerTypeId, promptAnswerType, selectedPromptAnswerType, setPromptAnswerTypeId] = useDropdown(prompt.prompt_answer_type_id, "Choose Prompt Answer Type", dropdownPromptAnswerTypes, oldPromptAnswerType)

    const oldPromptTemplate = rawPromptTemplates.filter((pt) => pt.id === prompt.promptTemplateId)[0]?.name ?? "..."
    const [dropdownPromptTemplate, promptTemplateId, promptTemplate, selectedPromptTemplate, setPromptTemplateId] = useDropdown(prompt.promptTemplateId, "Choose Prompt Template", dropdownPromptTemplates, oldPromptTemplate)

    const [dropdownPlaybookId, newPlaybookId, playbookName, selectedPlaybookType, setPlaybookId] = useDropdown(
        prompt.playbook_id,
        "Choose Playbook",
        dropdownPlaybooks,
        initialPlaybookName
    )
    const [dropdownStatusComponent, statusId, statusName, selectedStatus, setStatusId] = useDropdown(prompt.prompt_status, "Choose Status", dropdownStatus, prompt.prompt_status)

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const p = new Prompt(
            promptText,
            tagId,
            categoryId,
            promptTypeId,
            promptAnswerTypeId,
            newPlaybookId,
            promptTemplateId,
            selectedStatus ?? prompt.prompt_status
        )
        p.prompt_id = prompt.prompt_id

        const new_prompt: Prompt = await updatePrompt(supabase, p)

        if (!new_prompt) {
            pushNotificationMessage("There was a problem while editing this Prompt", "error")
            return 
        }

        setPrompt(new_prompt)
    
        /** Reset */
        setPromptText("")
        setCategoryId("")
        setPromptTypeId("")
        setPromptAnswerTypeId("")
        setPromptTemplateId("")
        setTagId("")
        setPlaybookId("")
        console.log(new_prompt)
        dispatch(editPrompt(new_prompt))
        pushNotificationMessage("Prompt was edited with Success", "success")
        //navigate(`/prompts/${new_prompt.prompt_id}`)
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputPromptId}
        {inputPromptText}
        {inputPromptType}
        {inputPromptAnswerType}
        {dropdownTagId}
        {dropdownCategoryId}
        {dropdownPlaybookId}
        {dropdownPromptTemplate}
        {dropdownStatusComponent}
        <br></br>
        <EditButton text='Save'/>
    </form>

}
