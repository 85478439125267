import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { RdxPromptsState, promptsInitialState } from '../states/promptsState';
import { fetchPrompts } from '../../database/promptsQueries';
import Prompt from '../../models/dto/prompt';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPromptsAsync = createAsyncThunk(
  'prompts/fetchPrompts',
  async ({supabase, documentTypeName}: {supabase:any, documentTypeName: string|null}) => {
    const response = await fetchPrompts(supabase, documentTypeName);
    // The value we return becomes the `fulfilled` action payload
    return response.prompts;
  }
);

export const promptsSlice = createSlice({
  name: 'prompts',
  initialState: promptsInitialState as RdxPromptsState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    receivePromptsList: (state: RdxPromptsState, action: PayloadAction<Prompt[]>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        const prompts: Prompt[] = action.payload;
        state.data = [...state.data, ...prompts]
    },
    addPrompt: (state: RdxPromptsState, action: PayloadAction<Prompt>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const prompt: Prompt = action.payload
      state.data = [...state.data, prompt]
    },
    removePrompt: (state: RdxPromptsState, action: PayloadAction<string>) => {
        const promptId: string = action.payload
        const newPrompts = state.data.filter(item => item.prompt_id !== promptId)
        state.data = newPrompts
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    editPrompt: (state: RdxPromptsState, action: PayloadAction<Prompt>) => {
        const newPrompt: Prompt = action.payload
        const new_prompts = [...state.data.filter(item => item.prompt_id !== newPrompt.prompt_id), newPrompt]
        state.data = new_prompts
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromptsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromptsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPromptsAsync.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

export const { receivePromptsList, addPrompt, removePrompt, editPrompt } = promptsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPrompts = (state: RootState) => state.prompts.data;
export const selectPromptsCounter = (state: RootState) => state.prompts.data.length;
export const selectPrompt = (state: RootState, id:string) => state.prompts.data.filter((p) => p.prompt_id == id)[0];

export default promptsSlice.reducer;