
import Prompt from "../models/dto/prompt";

export async function fetchPrompts(supabase: any, playbook_id: string|null) {
    let supabaseResult;
    if (playbook_id !== null) {
        supabaseResult = await supabase
        .from('prompts')
        .select(`prompt_id, prompt_text, tag_id, category_id, prompt_type_id, prompt_answer_type_id, playbook_id, prompt_template, prompt_status`)
        .eq('playbook_id', Number(playbook_id))
    } else {
        supabaseResult = await supabase
        .from('prompts')
        .select(`prompt_id, prompt_text, tag_id, category_id, prompt_type_id, prompt_answer_type_id, playbook_id, prompt_template, prompt_status`)
    }

    let { data, error, status } = supabaseResult
    
    console.log("Prompt Results:", data)

    if (error && status !== 406) {
        throw error
    }

    let prompts: Array<Prompt> = []
    if (data) {
        prompts = data.map(
            function(p: any) {
                return createPromptObject(p)
            }
        )
    }

    return { prompts, error, status }
}


export async function insertPrompt(supabase: any, prompt: Prompt): Promise<Prompt> {
    const { data, error } = await supabase
        .from('prompts')
        .insert({ 
            prompt_text: prompt.prompt_text,
            tag_id: prompt.tag_id,
            category_id: prompt.category_id,
            prompt_type_id: prompt.prompt_type_id,
            prompt_answer_type_id: prompt.prompt_answer_type_id,
            playbook_id: prompt.playbook_id,
            prompt_template: prompt.promptTemplateId === "" || prompt.promptTemplateId === "null" ? null : prompt.promptTemplateId,
            prompt_status: prompt.prompt_status
        })
        .select()
        .single()

    return createPromptObject(data)
}

export async function updatePrompt(supabase: any, prompt: Prompt): Promise<Prompt> {
    
    console.log("Attemtping to update prompt:", prompt)

    const { data, error } = await supabase
        .from('prompts')
        .update({
            prompt_text: prompt.prompt_text,
            tag_id: prompt.tag_id,
            category_id: Number(prompt.category_id),
            prompt_type_id: Number(prompt.prompt_type_id),
            prompt_answer_type_id: Number(prompt.prompt_answer_type_id),
            playbook_id: Number(prompt.playbook_id),
            prompt_template: prompt.promptTemplateId === "" || prompt.promptTemplateId === "null" ? null : prompt.promptTemplateId,
            prompt_status: prompt.prompt_status
        })
        .eq('prompt_id', Number(prompt.prompt_id))
        .select()
        .single()
    
        console.log("updated return:", data)

    return createPromptObject(data)
  }

  export async function deletePrompt(supabase: any, prompt: Prompt): Promise<Prompt> {
    
    const { data, error } = await supabase
        .from('prompts')
        .delete()
        .eq('prompt_id', Number(prompt.prompt_id))

    return prompt
  }

function createPromptObject(data:any){
    const prompt = new Prompt(
        data.prompt_text,
        data.tag_id,
        data.category_id,
        String(data.prompt_type_id),
        String(data.prompt_answer_type_id),
        String(data.playbook_id),
        String(data.prompt_template),
        data.prompt_status
    )
    prompt.prompt_id = String(data.prompt_id)
    return prompt
}