export default class Prompt{
    prompt_id: string
    prompt_text: string;
    tag_id: string;
    category_id: string;
    prompt_type_id: string;
    prompt_answer_type_id: string;
    playbook_id: string;
    promptTemplateId: string;
    prompt_status: string;

    constructor(    
        prompt_text: string,
        tag_id: string,
        category_id: string,
        prompt_type_id: string,
        prompt_answer_type_id: string,
        playbook_id: string,
        promptTemplateId: string,
        prompt_status: string
    ){
        this.prompt_id = "-1"
        this.prompt_text = prompt_text;
        this.tag_id = tag_id;
        this.category_id = category_id;
        this.prompt_type_id = prompt_type_id;
        this.prompt_answer_type_id = prompt_answer_type_id;
        this.playbook_id = playbook_id;
        this.promptTemplateId = promptTemplateId;
        this.prompt_status = prompt_status
    }

    toDict(){
        return new Map<string, any>([
            ["prompt_id", this.prompt_id],
            ["prompt_text", this.prompt_text],
            ["tag_id", this.tag_id],
            ["category_id", this.category_id],
            ["prompt_type_id", this.prompt_type_id],
            ["prompt_answer_type_id", this.prompt_answer_type_id],
            ["playbook_id", this.playbook_id],
            ["promptTemplateId", this.promptTemplateId],
            ["prompt_status", this.prompt_status]
        ])
    }
}