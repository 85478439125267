import DocumentType from "../models/dto/documentType";

export async function fetchDocumentTypes(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('document_types')
      .select(`id, name, short_name, description, playbook_id`)

    if (error && status !== 406) {
        throw error
    }

    let documentTypes: Array<DocumentType> = []
    if (data) {
        documentTypes = data.map(
            function(d: any) {
                return createDocumentTypeObject(d)
            }
        )
    }

    return { documentTypes, error, status }
  }

  export async function insertDocumentType(supabase: any, docType: DocumentType): Promise<DocumentType>  {
    
    const { data, error } = await supabase
        .from('document_types')
        .insert({ 
            description: docType.description,
            name: docType.name,
            playbook_id: docType.playbook_id,
            short_name: docType.short_name
        })
        .select()
        .single()

    return createDocumentTypeObject(data)
  }

  export async function updateDocumentType(supabase: any, docType: DocumentType): Promise<DocumentType> {
    
    const { data, error } = await supabase
        .from('document_types')
        .update({
            description: docType.description,
            name: docType.name,
            playbook_id: docType.playbook_id,
            short_name: docType.short_name
        })
        .eq('id', Number(docType.id))
        .select()
        .single()

    return createDocumentTypeObject(data)
  }

  export async function deleteDocumentType(supabase: any, docType: DocumentType): Promise<DocumentType> {
    
    const { data, error } = await supabase
        .from('document_types')
        .delete()
        .eq('id', Number(docType.id))

    return docType
  }

function createDocumentTypeObject(data:any){
    const docType = new DocumentType(data.description, data.name, data.short_name, data.playbook_id)
    docType.id = String(data.id)
    return docType
}
