import React, { EventHandler, useEffect, useState } from 'react'
import { Checkbox, useDropdown, useInput } from '../../../utils'
import FollowUp from '../../../models/dto/followUp';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { editFollowUp } from '../../../redux/reducers/followUpsReducer';
import { selectPrompts } from '../../../redux/reducers/promptsReducer';
import DropdownItem from '../../../models/dto/dropdownItem';
import Prompt from '../../../models/dto/prompt';
import { updateFollowUp } from '../../../database/followUpQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { toggleFollowUpActivationWidget } from './utils';
import { EditButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';

export default function EditFollowUpForm(
    { setFollowUp, followUp} : {setFollowUp: any, followUp: FollowUp}
    ) {

    let {paramFollowUpId} = useParams<{paramFollowUpId: string}>()
    console.log("Editing followUptId:", paramFollowUpId)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // Prompts
    const rawPrompts = useAppSelector(selectPrompts);
    const dropdownPrompts = rawPrompts.map(
        (item: Prompt) => new DropdownItem(item.prompt_id, item.prompt_text, item, item.prompt_id, item.prompt_status)
    )

    const [inputFollowUpId, followUpId, setFollowUpId] = useInput(followUp.id, "Follow Up Id", followUp.id, true)

    const oldOriginPromptText = rawPrompts.filter((p) => p.prompt_id === followUp.originPromptId)[0]?.prompt_text ?? "..."

    const [dropdownOriginPromptId, originPromptId, originPromptText, selectedOriginPrompt ,setOriginPromptId] = useDropdown(followUp.originPromptId, "Anchor Prompt", dropdownPrompts, oldOriginPromptText) 
    const [inputTriggerAnswer, triggerAnswer, setTriggerAnswer] = useInput(followUp.triggerAnswer, "Trigger Answer", followUp.triggerAnswer)

    const oldTargetPromptText = rawPrompts.filter((p) => p.prompt_id === followUp.targetPromptId)[0]?.prompt_text ?? "..."

    const [inputRiskScore, riskScore, setRiskScore] = useInput(String(followUp.riskScore), "Risk Score", String(followUp.riskScore))
    const [dropdownTargetPromptId, targetPromptId, targetPromptText, selectedTargetPrompt, setTargetPromptId] = useDropdown(followUp.targetPromptId, "Target Prompt", dropdownPrompts, oldTargetPromptText) 

    const [inputInfoNote, infoNote, setInfoNote] = useInput(followUp.note, "Information Note", followUp.note)

    const [isActive, setIsActive] = useState<boolean>(followUp.active)

    const toogleFollowUpActivation = (e: any) => {
        setIsActive(!isActive)
    }

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const f = new FollowUp(
            originPromptId,
            triggerAnswer,
            parseInt(riskScore),
            targetPromptId,
            infoNote,
            isActive
        )
        f.id = followUp.id

        const newFollowUp: FollowUp = await updateFollowUp(supabase, f)

        if (!newFollowUp) {
            pushNotificationMessage("There was a problem while editing this Follow Up", "error")
            return 
        }
    
        setFollowUp(newFollowUp)
    
        /** Reset */
        setOriginPromptId("")
        setTriggerAnswer("")
        setRiskScore("")
        setTargetPromptId("")
        console.log(newFollowUp)
        dispatch(editFollowUp(newFollowUp))
        pushNotificationMessage("Follow Up was edited with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputFollowUpId}
        {dropdownOriginPromptId}
        {inputTriggerAnswer}
        {inputRiskScore}
        {dropdownTargetPromptId}
        {inputInfoNote}
        <Checkbox headerText="Activate/Deactivate Follow Up" descriptionText="" toggleAction={toogleFollowUpActivation} currentState={isActive}/>
        <br></br>
        <EditButton text='Save'/>
    </form>

}
