export default class PromptType{
    id: string;
    promptType: string;

    constructor(    
        id: string,
        promptType: string
    ){
        this.id = id;
        this.promptType = promptType;
    }
}