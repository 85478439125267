import { useEffect, useState } from "react";
import Divider from "../../../components/dividers";
import DropdownItem from "../../../models/dto/dropdownItem";
import Prompt from "../../../models/dto/prompt";
import { useAppSelector } from "../../../redux/hooks";
import { selectPrompts } from "../../../redux/reducers/promptsReducer";
import { selectPromptsTemplates } from "../../../redux/reducers/promptsTemplatesReducer";
import { useDropdown, useInput } from "../../../utils";
import PromptsList from "../promptsViews/promptsList";
import { openai } from "../../../security/openai_utils";
import { CreateButton } from "../../../components/buttons";

export default function PromptTestingPreBuiltPage() {

    // Prompts
    const rawPrompts = useAppSelector(selectPrompts);
    const dropdownPrompts = rawPrompts.map(
        (item: Prompt) => new DropdownItem(item.prompt_id, item.prompt_text, item)
    )

    //Prompt Templates
    const rawPromptsTemplates = useAppSelector(selectPromptsTemplates);

    const [dropdownPromptId, promptId, promptText, selectedPrompt, setPromptId] = useDropdown("", "Choose Prompt", dropdownPrompts, "...")

    const [inputText, input, setInputText] = useInput("", "Prompt Text", "Type prompt text...")
    const [variableTags, setVariableTags] = useState<string[]>([])
    const [tagsText, setTagsText] = useState<string[]>([])
    const [promptTemplate, setPromptTemplate] = useState<string>()
    const [promptsList, setPromptsList] = useState<Prompt[]>([])

    const [output, setOutput] = useState<string>("")

    const detectPromptTagsInString = (text: string) => {
      console.log("text:", text)
      const promptTagsDetectorRegex = new RegExp(/\{\{([A-Za-z_]*)\}\}/g)
      const groups = text.match(promptTagsDetectorRegex) ?? []
      console.log("groups:", groups)
      const tags: string[] = groups.map((g) => g.replace("{{", "").replace("}}", ""))
      console.log("tags:", tags)
      return tags
    }

    useEffect(
      () => {
        console.log("selectedPrompt: ", selectedPrompt)
        setPromptsList(selectedPrompt ? [selectedPrompt] : [])
        if (selectedPrompt && selectedPrompt.promptTemplateId !== "null") {
          const promptTemplate = rawPromptsTemplates.filter((t) => t.id === selectedPrompt.promptTemplateId)[0]
          const tempTemplate = promptTemplate.getTemplateTextSimplified()
          const tags = detectPromptTagsInString(tempTemplate)
          setVariableTags(tags)
          setTagsText(tags.map((_x) => ""))
          setPromptTemplate(tempTemplate)
        }
        else{
          setVariableTags([])
          setTagsText([])
          setPromptTemplate("")
        }
      },
      [selectedPrompt]
    )

    useEffect(
      () => {
        let finalInput = promptTemplate ?? ""
        for (let t in variableTags){
          const tag = variableTags[t]
          finalInput = finalInput?.replace("{{"+tag+"}}", tagsText[t])
        }
        setInputText(finalInput)
      }
      , [tagsText]
    )
  
    const handleChange = (e: any) => {
      setInputText(e.target.value);
    };

    const handleButtonClick = async () => {
      console.log("promptInputText:", input)

      const completion = await openai.createCompletion({
        model: "gpt-3.5-turbo-instruct",
        prompt: input,
        temperature: 0.1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      }
      );
      let prompt_output = completion.data.choices[0].text ?? ""
      prompt_output = prompt_output.trim()
      setOutput(prompt_output);
    };

    const handleSubmit = (e: any) => { 
        e.preventDefault(); 
        handleButtonClick()
    }

    return (<>
        <div className='mb-10 z-10'>
            {dropdownPromptId}
        </div>
        <div className='mb-10'>
            <PromptsList key={"promptsListItems"} prompts={promptsList}/>
        </div>

        <form onSubmit={handleSubmit}>
            <div>
              <label>Prompt Template</label>
              <textarea
                className="bg-white-200 shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:bg-white focus:shadow-outline"
                id="template"
                key={"template"}
                value={promptTemplate}
                readOnly
              />
            </div>
            {variableTags.map(
              (tag, index) => <div>
                <label>{tag}</label>
                <textarea
              className="bg-white-200 shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:bg-white focus:shadow-outline"
              id={tagsText[index]+"_item"}
              key={index}
              value={tagsText[index]}
              onChange={(e) => {
                const tagsTextCopy = [...tagsText]
                tagsTextCopy[index] = e.target.value
                setTagsText(tagsTextCopy)
              }}
              />
            </div>
            )}
            <div className="flex mb-4">
                <div className="w-1/2 pr-4">
                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="input">
                    Input:
                    </label>
                    <textarea
                    className="bg-white-200 shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:bg-white focus:shadow-outline"
                    id="input"
                    value={input}
                    onChange={handleChange}
                    />
                </div>
            </div>
            <div className="mt-6 mb-6 text-left">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Evaluate
              </button>
            </div>
        </form>
        <div>
            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="output">
            Output:
            </label>
            <textarea
            className="bg-white-200 shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:bg-white focus:shadow-outline"
            id="output"
            value={output}
            readOnly
            />
        </div>
        </>
    )
}
