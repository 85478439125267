import Playbook from "../models/dto/playbook";

export async function fetchPlaybooks(supabase: any) {
    
    let { data, error, status } = await supabase
      .from('playbook')
      .select(`id, name, short_name, description, active`)

    if (error && status !== 406) {
        throw error
    }

    let playbooks: Array<Playbook> = []
    if (data) {
        playbooks = data.map(
            function(p: any) {
                return createPlaybookObject(p)
            }
        )
    }

    return { playbooks, error, status }
  }

export async function fetchPlaybook(supabase: any, playbook_id: string) {

    let { data, error, status } = await supabase
        .from('playbook')
        .select(`id, name, short_name, description, active`)
        .eq('id', Number(playbook_id))
        .single()

    if (error && status !== 406) {
        throw error
    }

    let playbook = createPlaybookObject(data)

    return { playbook, error, status }
}

  export async function insertPlaybook(supabase: any, playbook: Playbook): Promise<Playbook>  {
    
    const { data, error } = await supabase
        .from('playbook')
        .insert({ 
            description: playbook.description,
            name: playbook.name,
            short_name: playbook.short_name
        })
        .select()
        .single()

    return createPlaybookObject(data)
  }

  export async function updatePlaybook(supabase: any, playbook: Playbook): Promise<Playbook> {
    
    const { data, error } = await supabase
        .from('playbook')
        .update({
            description: playbook.description,
            name: playbook.name,
            short_name: playbook.short_name,
            active: playbook.active
        })
        .eq('id', Number(playbook.id))
        .select()
        .single()

    return createPlaybookObject(data)
  }

  export async function deletePlaybook(supabase: any, playbook: Playbook): Promise<Playbook> {
    
    const { data, error } = await supabase
        .from('playbook')
        .delete()
        .eq('id', Number(playbook.id))

    return playbook
  }

function createPlaybookObject(data:any){
    const playbook = new Playbook(data.description, data.name, data.short_name,data.active)
    playbook.id = String(data.id)
    return playbook
}
