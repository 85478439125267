import PromptAnswerType from "../../models/dto/promptAnswerType"
import PromptType from "../../models/dto/promptType"


interface RdxPromptAnswerTypesState {
    data: PromptAnswerType[],
    filter: string,
    loading: boolean,
    error: unknown
}

const promptAnswerTypesInitialState: RdxPromptAnswerTypesState = {
    data: [],
    filter: '',
    loading: false,
    error: null
}

export type {
    RdxPromptAnswerTypesState
}
export {
    promptAnswerTypesInitialState
}
