export const CreateButton = ({text}: {text: string}) => <button
    type="submit"
    className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
    {text}
</button>

export const EditButton = ({text}: {text: string}) => <button
    type="submit"
    className="mt-5 inline-flex items-center rounded-md border border-transparent bg-orange-100 px-3 py-2 text-sm font-medium leading-4 text-orange-700 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
    {text}
</button>

export const DeleteButton = ({text, onClickEvent}: {text: string, onClickEvent:any}) => <button
    type="submit"
    className="mt-5 inline-flex items-center rounded-md border border-transparent bg-red-100 px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    onClick={onClickEvent}>
    {text}
</button>