import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { RdxDocumentTypesState, docTypesInitialState } from '../states/documentTypesState';
import DocumentType from '../../models/dto/documentType';
import { fetchDocumentTypes } from '../../database/documentTypesQueries';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchDocumentTypesAsync = createAsyncThunk(
  'documentTypes/fetchDocumentTypes',
  async (supabase: any) => {
    const response = await fetchDocumentTypes(supabase);
    // The value we return becomes the `fulfilled` action payload
    return response.documentTypes;
  }
);

export const documentTypesSlice = createSlice({
  name: 'documentTypes',
  initialState: docTypesInitialState as RdxDocumentTypesState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    receiveDocumentTypesList: (state: RdxDocumentTypesState, action: PayloadAction<DocumentType[]>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        const documentTypes: DocumentType[] = action.payload;
        state.data = [...state.data, ...documentTypes]
    },
    addDocumentType: (state: RdxDocumentTypesState, action: PayloadAction<DocumentType>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const documentType: DocumentType = action.payload
      state.data = [...state.data, documentType]
    },
    removeDocumentType: (state: RdxDocumentTypesState, action: PayloadAction<string>) => {
        const documentTypeId: string = action.payload
        const updatedDocumentTypes = state.data.filter(item => item.id !== documentTypeId)
        state.data = updatedDocumentTypes
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    editDocumentType: (state: RdxDocumentTypesState, action: PayloadAction<DocumentType>) => {
        const documentType: DocumentType = action.payload
        const new_document_types = [...state.data.filter(item => item.id !== documentType.id), documentType]
        state.data = new_document_types
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocumentTypesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDocumentTypesAsync.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

export const { receiveDocumentTypesList, addDocumentType, removeDocumentType, editDocumentType } = documentTypesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDocumentTypes = (state: RootState) => state.documentTypes.data;
export const selectDocumentTypesCounter = (state: RootState) => state.documentTypes.data.length;
export const selectDocumentType = (state: RootState, id:string) => state.documentTypes.data.filter((d) => d.id == id)[0];

export default documentTypesSlice.reducer;