import {NavLink} from "react-router-dom";

export default function JobsListItem({job}: {job: any}) {

    const url_path = `https://app.legalreview.ai/contract/${job.id}/inline_review`
    console.log(job?.status[0])

    const navigationView = (id:string) => `/jobComments/${id}`

    return <><tr key={job.id}>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            <NavLink to={navigationView(job.id)} key={navigationView(job.id)} className="text-indigo-600 hover:text-indigo-900">
                <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">View</span>
                <span className="sr-only">, {job.id}</span>
            </NavLink>
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{job.id}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <a href={url_path}>{url_path}</a>
        </td>
        <td className="text-center px-3">
            <div className={`p-2 px-3 text-xs capitalize max-w-[95px] font-thin rounded-full ${job?.status[0]?.status === "completed"
                                                                                                                ?'text-green-600 bg-opacity-25 bg-green-500':
                                                                                                                job?.status[0]?.status === "processing"
                                                                                                                ?"text-blue-600 bg-baby-blue":
                                                                                                                job?.status[0]?.status === "new"
                                                                                                                ?"text-gray-600 bg-opacity-25 bg-gray-500":
                                                                                                                'bg-red-600 text-red bg-opacity-25 ' } 
                                                                                                            `}>
                <span className=''>{job?.status[0]?.status}</span>
            </div>
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">{job?.result[0]?.document_type}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job?.result[0]?.company_name}</td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job?.result[0]?.document_name}</td>
 
    </tr></>
}