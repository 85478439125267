import React, { EventHandler, useEffect, useState } from 'react'
import { useDropdown, useInput } from '../../../utils'
import DropdownItem from '../../../models/dto/dropdownItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Tag from '../../../models/dto/tag';
import Category from '../../../models/dto/category';
import { selectCategories } from '../../../redux/reducers/categoriesReducer';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { insertTag } from '../../../database/tagsQueries';
import { addTag } from '../../../redux/reducers/tagsReducer';
import { CreateButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';

export default function TagForm(
    { setTag}: {setTag: any}
    ) {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    // Categories
    const rawCategories = useAppSelector(selectCategories);
    const dropdownCategories = rawCategories.map(
        (item: Category) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    const [inputTagId, tagId, setTagId] = useInput("", "Tag Id", "Type Tag Id...")
    const [inputTagName, tagName, setTagName] = useInput("", "Tag Name", "Type Tag Name...")
    const [inputDescription, description, setDescription] = useInput("", "Description", "Type Tag Description...")
    const [dropdownCategoryId, categoryId, categoryName, selectedCategory, setCategoryId] = useDropdown("", "Choose Category", dropdownCategories, "Choose the category")

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const t = new Tag(
            tagName,
            description,
            categoryId,
        )
        t.id = tagId

        const new_tag: Tag = await insertTag(supabase, t)

        if (!new_tag) {
            pushNotificationMessage("There was a problem while creating this Tag", "error")
            return 
        }

        setTag(new_tag)
    
        /** Reset */
        setTagId("")
        setTagName("")
        setDescription("")
        setCategoryId("")
        console.log(new_tag)
        dispatch(addTag(new_tag))
        pushNotificationMessage("Tag was created with Success", "success")
        navigate(`/tags/${new_tag.id}`)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8" >
        {inputTagId}
        {inputTagName}
        {inputDescription}
        {dropdownCategoryId}
        <br></br>
        <CreateButton text='Add Tag'/>
    </form>

}
