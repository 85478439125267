import React, { EventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDropdown, useInput } from '../../../utils'
import DocumentType from '../../../models/dto/documentType';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addDocumentType, selectDocumentTypes} from '../../../redux/reducers/documentTypesReducer';
import { insertDocumentType } from '../../../database/documentTypesQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { CreateButton } from '../../buttons';
import { pushNotificationMessage } from '../../notifcationMessage';
import DropdownItem from "../../../models/dto/dropdownItem";
import {fetchPlaybooks} from "../../../database/playbookQueries";
import Playbook from "../../../models/dto/playbook";

export default function DocumentTypeForm({ setDocumentType }: any) {

    const [inputDocumentTypeName, documentTypeName, setDocumentTypeName] = useInput("", "Document Type", "Type Document Type Name...")
    const [inputDocumentTypeShortName, documentTypeShortName, setDocumentTypeShortName] = useInput("", "Document Type (Short Name)", "Type Document Type Short Name...")
    const [inputDescription, description, setDescription] = useInput("", "Description", "Type Description...")

    const [rawPlaybooks, setPlaybooks] = useState<Playbook[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    useEffect(() => {
        const fetch_playbooks = async () => {
            const pbs = await fetchPlaybooks(supabase);
            setPlaybooks(pbs.playbooks)
        };
        const _response = fetch_playbooks()
    },[])

    const dropdownPlaybooks = rawPlaybooks.map(
        (item: Playbook) => new DropdownItem(item.id, item.name, item, item.id, item.description)
    )

    let initialPlaybookId = "145" // General Document Playbook

    const [dropdownPlaybookId, newPlaybookId, playbookName, selectedPlaybook, setPlaybookId] = useDropdown(
        initialPlaybookId,
        "Choose Playbook",
        dropdownPlaybooks,
        initialPlaybookId
    )


    const handleSubmit = async (e: any) => { 
        e.preventDefault();
        //user input
        const docType = new DocumentType(
            description,
            documentTypeName,
            documentTypeShortName,
            newPlaybookId,
        )

        const newDocType: DocumentType = await insertDocumentType(supabase, docType)

        if (!newDocType) {
            pushNotificationMessage("There was a problem while creating this Document Type", "error")
            return 
        }
        setDocumentType(newDocType)
        /** Reset */
        setDocumentTypeName("")
        setDocumentTypeShortName("")
        setDescription("")
        console.log(newDocType)
        dispatch(addDocumentType(newDocType))
        pushNotificationMessage("Document Type was created with Success", "success")
        navigate(-1)
    }

    return <form onSubmit={handleSubmit} className="mb-8 mt-8">
        {inputDocumentTypeName}
        {inputDocumentTypeShortName}
        {inputDescription}
        {dropdownPlaybookId}
        <br></br>
        <CreateButton text='Add Document Type'/>
    </form>

}
