import DocumentType from "../../../models/dto/documentType"
import { useState, useEffect } from "react";
import { Session } from "@supabase/supabase-js";
/*import { supabase } from '../../../security/supabaseClient'*/
import { NavLink} from 'react-router-dom'
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
    selectDocumentTypes
} from '../../../redux/reducers/documentTypesReducer';
import DocumentTypesList from "./documentTypesList";
import { CreateButton } from "../../../components/buttons";

export default function DocumentTypesPage() {

    const documentTypes = useAppSelector(selectDocumentTypes);

    return <> 
        <h1 className="text-3xl font-bold mb-5">Document Types Page</h1>
        <NavLink to={`/documentTypes/new`}>
            <CreateButton text="Add Document Type"/>
        </NavLink>
        <DocumentTypesList documentTypes={documentTypes}/>
        </>
}