import { useEffect, useState } from "react";
import FollowUp from "../../../models/dto/followUp";
import FollowUpsListItem from "./followUpsListItem";
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function FollowUpsList( {followUps}: {followUps: Array<FollowUp>}) {

    const [sorting, setSorting] = useState({ field: 'id', ascending: false })
    const [currentFollowUps, setCurrentFollowUps] = useState<FollowUp[]>(followUps)

    function applySorting(field: string, ascending: boolean) {
      setSorting({ field: field, ascending: ascending });
    }

    useEffect(() => {
      // Copy array to prevent data mutation
      const currentFollowUpsCopy = [...followUps];

      // Apply sorting
      const sortedFollowUps = currentFollowUpsCopy.sort((f1: FollowUp, f2: FollowUp) => {
        const field: string = sorting.field
        const f1_m = f1.toDict()
        const f2_m = f2.toDict()
        const f1_m_field = `${f1_m.get(field)}`
        const f2_m_field = `${f2_m.get(field)}`
        return f1_m_field.localeCompare(f2_m_field, undefined, { numeric: true, sensitivity: 'base'});
      });

      // Replace currentUsers with sorted currentUsers
      setCurrentFollowUps(
        // Decide either currentUsers sorted by ascending or descending order
        sorting.ascending ? sortedFollowUps : sortedFollowUps.reverse()
      );
    }, [sorting]);

    return (<div className="mt-8 mb-8 flex flex-col">
    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("id", !sorting.ascending)}>
                    Id
                    <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("active", !sorting.ascending)}>
                    Active
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("originPromptId", !sorting.ascending)}>
                    Origin Prompt Id
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("triggerAnswer", !sorting.ascending)}>
                    Trigger Answer
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("riskScore", !sorting.ascending)}>
                    Risk Score
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("targetPromptId", !sorting.ascending)}>
                    Target Prompt Id
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <a href="#" className="group inline-flex" onClick={()=>applySorting("note", !sorting.ascending)}>
                    Information Note
                    <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </a>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">View</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {currentFollowUps.map(
                (followUp: FollowUp) => (
                    <FollowUpsListItem key={followUp.id} followUp={followUp}/>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>)
}