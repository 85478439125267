import React, { EventHandler, useEffect, useState } from 'react'
import { GoBackButton, useInput } from '../../../utils'
import EditTagForm from '../../../components/forms/tag/editTagForm';
import Tag from '../../../models/dto/tag';
import { selectTags } from '../../../redux/reducers/tagsReducer';
import { useAppSelector } from '../../../redux/hooks';
import { useParams } from 'react-router-dom';

export default function EditTag() {

    let {tagId} = useParams<{tagId: string}>();

    const [tag, setTag] = useState<Tag>();

    const tags = useAppSelector(selectTags)
    const oldTag = tags.filter((t) => t.id === tagId)[0]  

    console.log("fetched tags: ", tags)
    console.log("tag:", oldTag)

    return <>
        <h1 className="text-3xl font-bold">Tag Edition Form</h1>
        <EditTagForm setTag={setTag} tag={oldTag}/>
        <GoBackButton text={"Cancel"}/>
    </>

}
