import React, { EventHandler, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, useDropdown, useInput } from '../../../utils'
import { useAppDispatch } from '../../../redux/hooks';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import PromptTemplate from '../../../models/dto/promptTemplate';
import { addPromptTemplate } from '../../../redux/reducers/promptsTemplatesReducer';
import PromptTemplateModalForm from './promptTemplateModalForm';
import { insertPromptTemplate } from '../../../database/promptsTemplatesQueries';
import { CreateButton } from '../../buttons';
import { MultipleSelect } from '../../multipleSelectItems';
import Divider from '../../dividers';
import { pushNotificationMessage } from '../../notifcationMessage';

export default function PromptTemplateForm(
    { setPromptTemplate}: {
        setPromptTemplate: any, 
    }
    ) {

    const [openModel, setOpenModal] = useState(false)

    const [metaTemplateItems, setMetaTemplateItems] = useState<string[]>([])
    const [metaComponents, setMetaComponent] = useState<React.ReactElement<any>[]>([])
    const [isCustomTemplate, setIsCustomTemplate] = useState<boolean>(false)
    const [usedMetaElements, setUsedElements] = useState<string[]>([])

    const [inputTemplateName, templateName, setTemplateName] = useInput("", "Template Name", "Type Template Name...")

    const [introduction, setIntroduction] = useState<string>("")
    const [warning, setWarning] = useState<string>("")
    const [instructions, setInstructions] = useState<string>("")
    const [conditional_information, setConditionalInformation] = useState<string>("")
    const [dynamic_conditional_information, setDynamicConditionalInformation] = useState<string>("")

    const [few_shots, setFewShots] = useState<string[]>([])
    const [space, setSpace] = useState<string>(" ")
    const [final_space, setFinalSpace] = useState<string>(" ")
    const [customTemplate, setCustomTemplate] = useState<string>("")

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    const setMetaElement = (metaElement: string) => {
        switch (metaElement) {
            case "instruction":
                setIntroduction(metaElement)
                break
            case "warning":
                setWarning(metaElement)
                break
            case "instructions":
                setInstructions(metaElement)
                break
            case "conditional_information":
                setConditionalInformation(metaElement)
                break
            case "dynamic_conditional_information":
                setDynamicConditionalInformation(metaElement)
                break
            
        }
    }

    const addMetaComponent = (component: React.ReactElement<any>, metaElementUsed: string) => {
        const localMetaComponents = [...metaComponents, component]
        const localUsedMetaElements = [...usedMetaElements, metaElementUsed]
        setMetaElement(metaElementUsed)
        setUsedElements(localUsedMetaElements)
        setMetaComponent(localMetaComponents)
    }

    const toogleCustomTemplate = (e: any) => {
        setIsCustomTemplate(!isCustomTemplate)
    }

    const handleSubmit = async (e: any) => { 
        e.preventDefault(); 
        //user input
        const metaTemplate = metaTemplateItems.join("|")
        const promptTemplate = new PromptTemplate(
            "-1",
            templateName,
            metaTemplate,
            introduction,
            warning,
            instructions,
            conditional_information,
            dynamic_conditional_information,
            few_shots,
            space,
            final_space,
            customTemplate
        )

        console.log("promptTemplate:", promptTemplate)
        const newPromptTemplate: PromptTemplate = await insertPromptTemplate(supabase, promptTemplate)

        if (!newPromptTemplate) {
            //alert("There was an issue creating Prompt Template! " + promptTemplate)
            pushNotificationMessage("There was an issue creating Prompt Template! ", "error")
            return 
        }
        setPromptTemplate(newPromptTemplate)
        //*Reset
        setTemplateName("")
        setIntroduction("")
        setWarning("")
        setInstructions("")
        setConditionalInformation("")
        setDynamicConditionalInformation("")
        console.log(newPromptTemplate)
        
        dispatch(addPromptTemplate(newPromptTemplate))
        pushNotificationMessage("Prompt Template Created with Success", "success")
        navigate(-1)
    }

    const handleCustomTemplateChange = (e:any) => {
        setCustomTemplate(e.target.value)
    }

    const customTemplateWidget = (isActive: boolean) => <>
        {isActive ? <>
            <div className="form-control w-full">
                <div className='w-full w-1/2'>
                    <label className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">Custom Prompt Template</label>
                    <textarea className="textarea textarea-secondary w-full text-lg min-h-[10rem] max-h-[20rem]" placeholder="Enter template: use {{variable_name}} for dynamic variables" onChange={handleCustomTemplateChange}></textarea>
                </div>
            </div>
        </> : ""}
    </>

    const normalTemplateCreationFlow = (isActive: boolean) => <>
    {!isActive ? <>
        <MultipleSelect 
            placeholderHeaderText={"Prompt Template Items"}
            placeholderText={"Choose Items"}
            items={PromptTemplate.getFields().map((pt, index) => {return {id: index, text: pt}})} 
            setSelectedItems={setMetaTemplateItems}
        />
        <button type="button" className="inline-flex items-center rounded-md border border-transparent bg-green-100 px-3 py-2 text-sm font-medium leading-4 text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => setOpenModal(true)}>
            Add Meta Template Item
        </button>
        <PromptTemplateModalForm addMetaComponent={addMetaComponent} usedMetaElements={usedMetaElements} open={openModel} setOpen={setOpenModal}/>
        {metaComponents}
    </> : ""}
</>

    return<>
        {inputTemplateName}
        <Checkbox headerText="Activate/Deactivate Custom Template Design" descriptionText="" toggleAction={toogleCustomTemplate} currentState={isCustomTemplate}/>
        {customTemplateWidget(isCustomTemplate)}
        {normalTemplateCreationFlow(isCustomTemplate)}
        <Divider text=''/>
        <form onSubmit={handleSubmit} className="mb-8 mt-8">
            <CreateButton text='Add Prompt Template'/>
        </form>
    </>

}
