import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import Category from '../../models/dto/category';
import { RdxCategoriesState, categoriesInitialState } from '../states/categoryState';
import { fetchCategories } from '../../database/categoriesQueries';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCategoriesAsync = createAsyncThunk(
  'categories/fetchCategories',
  async (supabase: any) => {
    const response = await fetchCategories(supabase);
    // The value we return becomes the `fulfilled` action payload
    return response.categories;
  }
);

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState as RdxCategoriesState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    receiveCategories: (state: RdxCategoriesState, action: PayloadAction<Category[]>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        const categories: Category[] = action.payload;
        state.data = [...state.data, ...categories]
    },
    addCategory: (state: RdxCategoriesState, action: PayloadAction<Category>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const category: Category = action.payload
      state.data = [...state.data, category]
    },
    removeCategory: (state: RdxCategoriesState, action: PayloadAction<string>) => {
        const categoryId: string = action.payload
        const updatedCategories = state.data.filter(item => item.id !== categoryId)
        state.data = updatedCategories
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    editCategory: (state: RdxCategoriesState, action: PayloadAction<Category>) => {
        const category: Category = action.payload
        const new_categories = [...state.data.filter(item => item.id !== category.id), category]
        state.data = new_categories
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCategoriesAsync.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

export const { receiveCategories, addCategory, removeCategory, editCategory } = categoriesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCategories= (state: RootState) => state.categories.data;
export const selectCategoriesCounter= (state: RootState) => state.categories.data.length;

export default categoriesSlice.reducer;