
import { useState, useEffect } from 'react';
import PromptsList from '../promptsViews/promptsList'; 
import DocumentTypeInfo from './documentTypeInfo';
import DocumentType from '../../../models/dto/documentType';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  removeDocumentType,
    selectDocumentTypes
  } from '../../../redux/reducers/documentTypesReducer';

  import {
    selectPrompts
  } from '../../../redux/reducers/promptsReducer';
import { deleteDocumentType } from '../../../database/documentTypesQueries';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { CreateButton, DeleteButton, EditButton } from '../../../components/buttons';
import Divider from '../../../components/dividers';
import { popUpConfirmAlert } from '../../../components/confirmPopUp';
import { pushNotificationMessage } from '../../../components/notifcationMessage';

export default function DocumentTypeDetail() {
    let {documentTypeId} = useParams<{documentTypeId: string}>();

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const supabase = useSupabaseClient()

    console.log("here: ", documentTypeId)

    const documentTypes = useAppSelector(selectDocumentTypes);
    const documentType = documentTypes.filter((d) => d.id === documentTypeId)[0]

    const prompts = useAppSelector(selectPrompts);
    const documentTypesPrompts = prompts.filter((p) => p.playbook_id === documentType.playbook_id)

    const delDocumentType = async () => {
        const deletedDocType: DocumentType = await deleteDocumentType(supabase, documentType)

        if (!deletedDocType) {
            pushNotificationMessage("There was an issue while deleting Document Type", "error")
            return 
        }
        dispatch(removeDocumentType(documentTypeId ? documentTypeId : ""))
        pushNotificationMessage("Document Type was deleted with Success", "success")
        navigate("/documentTypes")
  }

    return (
        <div>
            <DocumentTypeInfo documentType={documentType}/>

            <div className="inline-flex items-center">
                <NavLink to={`/documentTypes/${documentTypeId}/edit`}>
                    <EditButton text="Edit Document Type"/>
                </NavLink>
                <div className='ml-5'>
                    <DeleteButton text="Delete Document Type" onClickEvent={() => popUpConfirmAlert(
                    "Confirm to Delete this Document Type.",
                    `Are you sure you want to delete Document Type: ${documentTypeId}? This Action cannot be undone.`,
                    delDocumentType,
                    () => {}
                )}/>
                </div>
            </div>

            <Divider text="Document Prompts"/>

            <NavLink to={`/documentTypes/${documentTypeId}/prompts/new`}>
                <CreateButton text="Add New Prompt"/>
            </NavLink>

            <PromptsList prompts={documentTypesPrompts}/>
        </div>
    )
}