import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Auth, ThemeSupa } from "@supabase/auth-ui-react"
import { customTheme } from "./theme"

export const CustomAuth = () => {

    const supabaseClient = useSupabaseClient()

    return (
    <div className="">
      <div className="" style={{ padding: '100px 100px 100px 100px'}}>
      <Auth
        providers={["google"]}
        supabaseClient={supabaseClient}
        appearance={{theme: customTheme}}
        theme="default"
        socialLayout="horizontal"
        redirectTo='prompts.legalreview.ai'
      />
      </div>
    </div>
    )
  }